import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import img_logo from "../../assets/logo.png";
import img_logo_svg from "../../assets/logo.svg";
import { Close } from "../Icon/Close";
import { Hamburger } from "../Icon/Hamburger";

const Header = (props) => {
  const menuData = ["Home", "Curate", "Distribute", "Celebrate"];
  const [showDropDown, setShowDropDown] = useState(false);
  const location = useLocation();
  const currentRouter = location.pathname;
  return (
    <>
      <div className="header-container">
        <a href="/">
          <img alt="logo" src={img_logo_svg} />
        </a>
        <div className="links">
          {menuData.map((item, i) =>
            item !== "Celebrate" ? (
              <Link
                to={item.toLowerCase()}
                className={`link-item ${
                  currentRouter.indexOf(item.toLowerCase()) !== -1 ||
                  (currentRouter === "/" && item === "Home")
                    ? "active"
                    : ""
                }`}
                key={i}
              >
                {item}
              </Link>
            ) : (
              <a className="link-item" href="http://bne.live" key={i}>
                {item}
              </a>
            )
          )}
          {!showDropDown ? (
            <Hamburger onClick={() => setShowDropDown(true)} />
          ) : (
            <Close onClick={() => setShowDropDown(false)} />
          )}
        </div>
      </div>
      {showDropDown ? (
        <div className="ham-dropdown">
          <ul>
            {menuData.map((item, i) => (
              <li key={i}>
                <div
                  className={
                    currentRouter.indexOf(item.toLowerCase()) !== -1 ||
                    (currentRouter === "/" && item === "Home")
                      ? "active"
                      : ""
                  }
                >
                  {item === "Celebrate" ? (
                    <a href="http://bne.live" key={i}>
                      {item}
                    </a>
                  ) : (
                    <Link
                      to={item.toLowerCase()}
                      key={i}
                      onClick={() => setShowDropDown(false)}
                    >
                      {item}
                    </Link>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Header;
