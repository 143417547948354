import React, { useState } from 'react'
import logo from "../assets/logo.svg";
import { Link, useNavigate } from 'react-router-dom';
import '../sass/auth.scss'
import { auth } from '../firebase';
import { sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';

const Login = () => {

    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const [error, setError] = useState('')

    const handleError = (errorMsg) => {
        setError(errorMsg)
        setTimeout(() => {
            setError('')
        }, 3000);
    }


    const login = (e) => {
        e.preventDefault()
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user
            localStorage.setItem('uid', user.uid)
            navigate('/dashboard/panel');
        })
        .catch((error) => {
            const errorCode = error.code;
            console.log(errorCode)
            errorCode == 'auth/user-not-found' && handleError('User doesn\'t exist')
            errorCode == 'auth/wrong-password' && handleError('Incorrect Password')
            errorCode == 'auth/invalid-login-credentials' && handleError('Invalid Login Credentials')
            // ..
        });
    }



  return (
    <div className='auth-page'>

        <form className="container py-5" onSubmit={login}>
            <div className="row">
                <div className="col-lg-6 auth-container">
                    <Link to={'/'} className='auth-logo'>
                        <img src={logo} alt="" />
                    </Link>

                    <h1>Login</h1>
                    <h6>
                        Please enter your email address and password to log in and access your account
                    </h6>
                    <div className='auth-form'>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className='auth-form-input' placeholder='Email' required />
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className='auth-form-input' placeholder='Password' required />
                    </div>

                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-lg-6'>
                    {
                        error !== '' && <h5 className='text-danger text-center mb-3'>{error}</h5>
                    }
                    <button className='auth-form-btn' type='submit'>
                        <span>Login</span>
                    </button>
                    <h6 className='auth-message'>Forgot Your Password? <Link to={'/resetPassword'}>Reset Password</Link></h6>
                </div>
            </div>
        </form>
        
    </div>
  )
}

export default Login