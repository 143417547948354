import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Bar, Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js/auto'
import { child, get, ref, set } from 'firebase/database'
import { db } from '../../firebase'
import { CSVLink } from 'react-csv';

const Analytics = () => {

  const uid = localStorage.getItem('uid')
  const [labels, setLabels] = useState([])
  const [reports, setReports] = useState([])
  const [values, setValues] = useState([])
  const [topPlatforms, setTopPlatforms] = useState([])
  const [topCountries, setTopCountries] = useState([])
  const [topReleases, setTopReleases] = useState([])
  const [releases, setReleases] = useState([])
  const [releaseTracks, setReleaseTracks] = useState([])
  const [previewTitle, setPreviewTitle] = useState('')
  const [balance, setBalance] = useState(0)
  const [totalPaid, setTotalPaid] = useState(0)
  const [lastMonthRevenue, setLastMonthRevenue] = useState(0)

  useEffect(() => {
    get(child(ref(db, 'users'), uid))
    .then((snapshot) => {
      if (snapshot.val() && snapshot.val().reports) {
        setValues(snapshot.val().reports)
        setLastMonthRevenue(snapshot.val().reports[snapshot.val().reports.length - 1].totalPrice.toFixed(0))
        setTopPlatforms(snapshot.val().sorting.channels)
        setTopCountries(snapshot.val().sorting.countries)
        setTopReleases(snapshot.val().sorting.releases)
        setReleases(snapshot.val().groupedReleases)
      }
      snapshot.val().totalPaid !== undefined && setTotalPaid(snapshot.val().totalPaid.toFixed(0))
      snapshot.val().balance !== undefined && setBalance(snapshot.val().balance.toFixed(0))
    })
  }, [])

  const ctx = document.createElement('canvas').getContext('2d');
  const gradient = ctx.createLinearGradient(0, 0, 0, 300);
  gradient.addColorStop(0, '#4b95e9');    // Start color (#4b95e9)
  gradient.addColorStop(1, '#4b95e93a');

  const data = {
    labels: values.map(item => item.monthYear),
    datasets: [
      {
        label: 'Monthly Revenue ($)',
        data: values.map(item => item.totalPrice.toFixed(0)),
        borderColor: '#337CCF',
        backgroundColor: gradient,
        borderWidth: 1,
      },
    ]
  };
  

  const ChannelsData = {
    labels: topPlatforms.map(platform => platform.channel),
    datasets: [
      {
        data: topPlatforms.map(platform => platform.totalRevenue.toFixed(0)), // Example data values for each category
        backgroundColor: ['#4b95e9', '#e74c3c', '#f39c12', '#16a085'], // Example colors for each category
      },
    ],
  };

  const options = {
    animation: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = '';
            if (context.formattedValue !== null) {
              label += '$' + context.formattedValue; // Add your unit here
            }
            return label;
          },
        },
      },
    }
  };



  /////// RELEASES PAGINATION ////////
  const [sortOrder, setSortOrder] = useState("asc")

  const handleSort = (property) => {
      setCurrentPage(1)
      const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
      const sortedData = [...releases].sort((a, b) => {
      if (property === "release") {
          return (sortOrder === "asc" ? 1 : -1) * a.release.localeCompare(b.release);
      } else if (property === "revenue") {
          return (sortOrder === "asc" ? 1 : -1) * (a.revenue - b.revenue);
      } else if (property === "streams") {
        return (sortOrder === "asc" ? 1 : -1) * (a.streams - b.streams);
      }
      return 0;
    });

      setReleases(sortedData);
      setSortOrder(newSortOrder);
  };


  /////////// PAGINATION ///////////
  const itemsPerPage = 30;
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = releases.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(releases.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
  };
  


  ///////////// FLATEN RELEASES ///////////////
  
  const flattenReleases = releases.reduce((accumulator, item) => {
    const { release, tracks } = item;
    const flatTracks = tracks.map(track => ({
      release: release,
      track: track.track,
      streams: track.streams,
      revenue: track.revenue
    }));
    return [...accumulator, ...flatTracks];
  }, []);


  return (
    <div className='dash-section'>
        <h1 className='dash-title'>Analytics</h1>
        <div className="row">
          <div className="col-lg-4 col-md-6 mt-3">
              <div className="dash-card dash-widget">
                <div className="dash-widget-left">
                  <h1>${balance}</h1>
                  <h5>Current Balance</h5>
                </div>
                <div className="dash-widget-right">
                  <i class="fi fi-sr-donate"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-3">
              <div className="dash-card dash-widget">
                <div className="dash-widget-left">
                  <h1>${lastMonthRevenue}</h1>
                  <h5>Latest Month Revenue</h5>
                </div>
                <div className="dash-widget-right">
                    <i class="fi fi-sr-hand-holding-usd"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-3">
              <div className="dash-card dash-widget">
                <div className="dash-widget-left">
                    <h1>${totalPaid}</h1>
                    <h5>Total Paid</h5>
                </div>
                <div className="dash-widget-right">
                    <i class="fi fi-ss-piggy-bank"></i>
                </div>
              </div>
            </div>
        </div>
        <div className="row">
          <div className="col-12 mt-4">
            <div className="dash-card">
              <div className="dash-card-heading mb-3">
                  <h2 className="dash-card-title">Monthly Revenue</h2>
              </div>
              <Bar data={data} options={options} />
            </div>
          </div>
          <div className="col-lg-6 mt-4">
            <div className="dash-card mb-4 sorting-card">
              <div className="dash-card-heading mb-2">
                <h2 className="dash-card-title">Top Countries</h2>
              </div>
              <div className="top-items">
                {
                  topCountries.map((country, i) => (
                    <div className="top-item" key={i}>
                      <h6>{i + 1}. {country.country}</h6>
                      <span>${country.totalRevenue.toFixed(2)}</span>
                    </div>
                  ))
                }
              </div>
            </div>
            <div className="dash-card sorting-card">
              <div className="dash-card-heading mb-2">
                <h2 className="dash-card-title">Top Releases</h2>
              </div>
              <div className="top-items">
                {
                  topReleases.map((release, i) => (
                    <div className="top-item" key={i}>
                      <h6>{i + 1}. {release.release}</h6>
                      <span>${release.totalRevenue.toFixed(2)}</span>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt-4">
            <div className="dash-card">
              <div className="dash-card-heading mb-3">
                <h2 className="dash-card-title">Top Platforms</h2>
              </div>
              <Doughnut data={ChannelsData} options={options} />
            </div>
          </div>

          <div className="col-12 mt-4">
              <div className="dash-card">
                  <div className="dash-card-heading">
                      <h2 className="dash-card-title">
                          Releases
                      </h2>
                      <CSVLink className="dash-card-heading-btn" data={flattenReleases} filename={'releases.csv'}>
                        <i className="fi fi-rr-file-export me-2"></i>
                        Export CSV
                      </CSVLink>
                  </div>
                  <div className="table-responsive mt-3">
                      <table className="table dash-table">
                          <thead>
                              <tr>
                                  <td>
                                    <div className='table-sort' onClick={() => handleSort('release')}>
                                      Releases Title
                                      {
                                          sortOrder === "asc" ? <i className="fi fi-ss-angle-small-down"></i>
                                          : <i className="fi fi-ss-angle-small-up"></i>
                                      }
                                    </div>
                                  </td>
                                  <td>
                                    <div className='table-sort' onClick={() => handleSort('streams')}>
                                      Streams
                                      {
                                          sortOrder === "asc" ? <i className="fi fi-ss-angle-small-down"></i>
                                          : <i className="fi fi-ss-angle-small-up"></i>
                                      }
                                    </div>
                                  </td>
                                  <td>
                                    <div className='table-sort' onClick={() => handleSort('revenue')}>
                                      Revenue
                                      {
                                          sortOrder === "asc" ? <i className="fi fi-ss-angle-small-down"></i>
                                          : <i className="fi fi-ss-angle-small-up"></i>
                                      }
                                    </div>
                                  </td>
                                  <td>Action</td>
                              </tr>
                          </thead>
                          <tbody>
                            {
                              currentItems.map((release, i) => (
                                <tr key={i}>
                                    <td>
                                        <div className="table-item">
                                            {release.release}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="table-item">
                                            {release.streams}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="table-item">
                                            ${release.revenue.toFixed(2)}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="table-item">
                                            <button 
                                              className='table-btn' 
                                              data-bs-toggle="modal"
                                              data-bs-target='#tracksModal'
                                              onClick={() => {
                                                setReleaseTracks(release.tracks);
                                                setPreviewTitle(release.release);
                                              }}
                                            >Details</button>
                                        </div>
                                    </td>
                                </tr>
                              ))
                            }
                          </tbody>
                      </table>
                  </div>
                  <div className="table-pagination">
                      {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                      <button key={pageNumber} className={currentPage === pageNumber && 'active'} onClick={() => handlePageChange(pageNumber)}>
                          {pageNumber}
                      </button>
                      ))}
                  </div>
              </div>
          </div>
        </div>

        {/* MODAL */}
        <div className="modal fade" id="tracksModal" tabindex="-1" aria-labelledby="tracksModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                  <div className="modal-header">
                      <div className="modal-top">
                          <h5>{previewTitle} Tracks</h5>
                          <i className="fi fi-rr-cross" data-bs-dismiss="modal"></i>
                      </div>
                  </div>
                  <div className="modal-body">
                    <div className="table-responsive mt-3">
                        <table className="table dash-table">
                            <thead>
                                <tr>
                                    <td>Track Title</td>
                                    <td>Streams</td>
                                    <td>Revenue</td>
                                </tr>
                            </thead>
                            <tbody>
                              {
                                releaseTracks && releaseTracks.map((track, i) => (
                                  <tr key={i}>
                                      <td>
                                          <div className="table-item">
                                              {track.track}
                                          </div>
                                      </td>
                                      <td>
                                          <div className="table-item">
                                              {track.streams}
                                          </div>
                                      </td>
                                      <td>
                                          <div className="table-item">
                                              ${(track.revenue * 1).toFixed(2)} 
                                              {
                                                track.share !== 100 && <span className='track-split-text'>({track.share}% Split)</span>
                                              }
                                          </div>
                                      </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                        </table>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Analytics