import { child, get, ref } from 'firebase/database'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { db } from '../../firebase'

const Claims = () => {

    const uid = localStorage.getItem('uid')
    const [claims, setClaims] = useState([])
    const [filteredClaims, setFilteredClaims] = useState([])

    useEffect(() => {
        get(child(ref(db, 'users'), uid))
        .then(snapshot => {
            if (snapshot.val().claims !== undefined) {
                setClaims(snapshot.val().claims)
                setFilteredClaims(snapshot.val().claims)
            } else {
                //
            }
        })
    }, [])


    const handleSearch = (event) => {
        const term = event.target.value;

        setCurrentPage(1)
    
        const filteredResults = claims.filter((claim) => {
          const { track, artist, channel } = claim;
          const lowerCaseTerm = term.toLowerCase();
    
          // Check if the item's properties contain the search term
          return (
            track.toLowerCase().includes(lowerCaseTerm) ||
            artist.toLowerCase().includes(lowerCaseTerm) ||
            channel.toLowerCase().includes(lowerCaseTerm)
          );
        });
    
        setFilteredClaims(filteredResults);
    };

    const [sortOrder, setSortOrder] = useState("asc")

    const handleSort = (property) => {
        setCurrentPage(1)
        const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
        const sortedData = [...filteredClaims].sort((a, b) => {
        if (property === "track") {
            return (sortOrder === "asc" ? 1 : -1) * a.track.localeCompare(b.track);
        } else if (property === "views") {
            return (sortOrder === "asc" ? 1 : -1) * (a.views - b.views);
        }
        return 0;
        });

        setFilteredClaims(sortedData);
        setSortOrder(newSortOrder);
    };


    /////////// PAGINATION ///////////
    const itemsPerPage = 50;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredClaims.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(filteredClaims.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };
    

  return (
    <div className='dash-section'>
        <h1 className='dash-title'>Claims</h1>
        <div className="row">
            <div className="col-12 mt-4">
                <div className="dash-card">
                    <div className="dash-card-heading mb-2">
                        <h2 className="dash-card-title">Claims Report</h2>
                    </div>
                    <input type="text" className='dash-input' onChange={handleSearch} placeholder='Search...' />
                    <div className="table-responsive mt-3">
                        <table className="table dash-table">
                            <thead>
                                <tr>
                                    <td>
                                        <div className='table-sort' onClick={() => handleSort('track')}>
                                            Track
                                            {
                                                sortOrder === "asc" ? <i className="fi fi-ss-angle-small-down"></i>
                                                : <i className="fi fi-ss-angle-small-up"></i>
                                            }
                                        </div>
                                    </td>
                                    <td>Artist</td>
                                    <td>Channel Claimed</td>
                                    <td>Claim URL</td>
                                    <td>
                                        <div className='table-sort' onClick={() => handleSort('views')}>
                                            Views
                                            {
                                                sortOrder === "asc" ? <i className="fi fi-ss-angle-small-down"></i>
                                                : <i className="fi fi-ss-angle-small-up"></i>
                                            }
                                        </div>
                                    </td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    currentItems.map((claim, i) => (
                                        <>
                                            <tr key={i}>
                                                <td>
                                                    <div className="table-item">
                                                        {claim.track}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        {claim.artist}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        {claim.channel}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        https://youtu.be/{claim.url}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        {claim.views}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        <Link to={'#'}>Release</Link> / <Link to={'#'} className='text-danger'>Takedown</Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        </>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="table-pagination">
                        {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                        <button key={pageNumber} className={currentPage === pageNumber && 'active'} onClick={() => handlePageChange(pageNumber)}>
                            {pageNumber}
                        </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Claims