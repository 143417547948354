import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { auth } from '../firebase'
import { onAuthStateChanged } from 'firebase/auth'
import AdminUsers from '../components/AdminDashboard/AdminUsers'
import AdminSidebar from '../components/AdminDashboard/AdminSidebar'
import AdminPayouts from '../components/AdminDashboard/AdminPayouts'
import AdminReleases from '../components/AdminDashboard/AdminReleases'
import AdminArtists from '../components/AdminDashboard/AdminArtists'
import AdminLabels from '../components/AdminDashboard/AdminLabels'
import AdminEditRelease from '../components/AdminDashboard/AdminEditRelease'
import AdminEditArtist from '../components/AdminDashboard/AdminEditArtist'
import AdminEditLabel from '../components/AdminDashboard/AdminEditLabel'
import AdminEditUser from '../components/AdminDashboard/AdminEditUser'

const AdminDashboard = () => {

    // const navigate = useNavigate()

    // useEffect(() => {
    //     onAuthStateChanged(auth, (user) => {
    //       if (user) {
    //         //
    //       } else {
    //         // User is signed out
    //         localStorage.removeItem('uid')
    //         navigate('/')
    //       }
    //     });
    // }, [])

  return (
    <div className='dashboard'>
        <div className="container">
            <div className="row">
                <div className="col-lg-3 py-2 dash-sidebar">
                    <AdminSidebar />
                </div>
                <div className="col-lg-9 py-2">
                    {/* <DashboardHeader /> */}
                    <br />
                    <Routes>
                        <Route element={<AdminUsers />} path="users" />
                        <Route element={<AdminEditUser />} path="users/:uid" />
                        <Route element={<AdminPayouts />} path="payouts" />
                        <Route element={<AdminReleases />} path="releases" />
                        <Route element={<AdminEditRelease />} path="releases/edit/:uid/:index" />
                        <Route element={<AdminArtists />} path="artists" />
                        <Route element={<AdminEditArtist />} path="artists/edit/:uid/:index" />
                        <Route element={<AdminLabels />} path="labels" />
                        <Route element={<AdminEditLabel />} path="labels/edit/:uid/:index" />
                    </Routes>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdminDashboard