import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import logo from '../../assets/logo.svg'

const AdminSidebar = () => {
  return (
    <div className='sidebar'>
        <Link to={'/'} className='sidebar-logo'>
            <img src={logo} alt="" />
        </Link>
        <div className="sidebar-links">
            <NavLink to={'users'} ativeClassName="active">
                <i className="fi fi-rr-users-alt"></i>
                Users
            </NavLink>
            <NavLink to={'payouts'} ativeClassName="active">
                <i className="fi fi-tr-usd-circle"></i>
                Payouts Requests
            </NavLink>
            <NavLink to={'releases'} ativeClassName="active">
                <i className="fi fi-ts-rocket-lunch"></i>
                Releases
            </NavLink>
            <NavLink to={'artists'} ativeClassName="active">
                <i className="fi fi-tr-music-note"></i>
                Artists
            </NavLink>
            <NavLink to={'labels'} ativeClassName="active">
                <i className="fi fi-tr-ticket-alt"></i>
                Labels
            </NavLink>
        </div>
    </div>
  )
}

export default AdminSidebar