import { child, get, ref, update } from 'firebase/database'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { db } from '../../firebase'

const AddArtist = () => {

    const successBtn = useRef()
    const uid = localStorage.getItem('uid')

    const [artists, setArtists] = useState([])

    const [name, setName] = useState('')
    const [website, setWebsite] = useState('')
    const [facebook, setFacebook] = useState('')
    const [twitter, setTwitter] = useState('')
    const [instagram, setInstagram] = useState('')
    const [youtube, setYoutube] = useState('')
    const [spotify, setSpotify] = useState('')
    const [apple, setApple] = useState('')
    const [deezer, setDeezer] = useState('')
    const [soundCloud, setSoundCloud] = useState('')

    //////////// GET USER'S RELEASES ////////////
    useEffect(() => {
        get(child(ref(db, 'users'), uid))
        .then(snapshot => {
            if (snapshot.val().artists !== undefined) {
                setArtists(snapshot.val().artists); 
            } else {
                //
            }
        })
    }, [])


    const clearForm = () => {
        setName('');
        setWebsite('');
        setFacebook('');
        setTwitter('');
        setInstagram('');
        setYoutube('');
        setSpotify('');
        setApple('');
        setDeezer('');
        setSoundCloud('');
    }

    
    const handleAddArtist = () => {

        const artist = {
            name,
            website,
            facebook,
            twitter,
            instagram,
            youtube,
            spotify,
            apple,
            deezer,
            soundCloud
        }
        
        // set(child(ref(db, 'releases'), releaseKey), release)
        const newArtists = [...artists, artist]
        update(child(ref(db, 'users'), uid), {
            artists: newArtists
        })
        clearForm()
        successBtn.current.click()
    }

  return (
    <div className='dash-section'>
        <h1 className='dash-title'>New Artist</h1>
        <div className="row">
            <div className="col-12 mt-4">
                <div className="dash-card">
                    <div className="row">
                        <div className="col-12">
                            <h4 className='dash-form-heading'>General Information</h4>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Name</label>
                                <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder='Name' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Website</label>
                                <input value={website} onChange={(e) => setWebsite(e.target.value)} type="text" placeholder='Website' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-12 mt-4">
                            <h4 className='dash-form-heading'>Profile Information</h4>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Facebook</label>
                                <input value={facebook} onChange={(e) => setFacebook(e.target.value)} type="text" placeholder='Facebook' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Twitter</label>
                                <input value={twitter} onChange={(e) => setTwitter(e.target.value)} type="text" placeholder='Twitter' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Instagram</label>
                                <input value={instagram} onChange={(e) => setInstagram(e.target.value)} type="text" placeholder='Instagram' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Youtube</label>
                                <input value={youtube} onChange={(e) => setYoutube(e.target.value)} type="text" placeholder='Youtube' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Spotify</label>
                                <input value={spotify} onChange={(e) => setSpotify(e.target.value)} type="text" placeholder='Spotify' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Apple</label>
                                <input value={apple} onChange={(e) => setApple(e.target.value)} type="text" placeholder='Apple' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Deezer</label>
                                <input value={deezer} onChange={(e) => setDeezer(e.target.value)} type="text" placeholder='Deezer' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>SoundCloud</label>
                                <input value={soundCloud} onChange={(e) => setSoundCloud(e.target.value)} type="text" placeholder='SoundCloud' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="dash-form-btns">
                                <button className='dash-form-btn' onClick={handleAddArtist}>Submit</button>
                                <button ref={successBtn} data-bs-toggle="modal" data-bs-target="#successModal" hidden></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div className="modal fade" id="successModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="successModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a href="/dashboard/artists" className='success-close-btn'>
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Artist Added Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AddArtist