import React, { useEffect, useState } from 'react'
import logo from "../assets/logo.svg";
import { Link, useNavigate, useParams } from 'react-router-dom';
import '../sass/auth.scss'
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { child, get, ref, remove, set } from 'firebase/database';
import emails from '../arrays/emails.json'
import axios from 'axios';

const CustomLogin = () => {

    const { id } = useParams()
    const navigate = useNavigate()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [user, setUser] = useState('')

    const [btnText, setBtnText] = useState('Login')
    const [error, setError] = useState('')

    const handleError = (errorMsg) => {
        setError(errorMsg)
        setTimeout(() => {
            setError('')
        }, 3000);
    }


    useEffect(() => {
        get(child(ref(db, 'users'), id))
        .then(snapshot => {
            const userData = snapshot.val()
            if (userData === null) {
                navigate('/')
            } else {
                setUser(userData)
            }
        })
    })


    const login = (e) => {
        e.preventDefault()
        if (email !== user.email) {
            handleError('Incorrect Email Address')
        } else if (password !== user.password) {
            handleError('Incorrect Password')
        } else {
            setBtnText('Please Wait...')
            const { password, ...newUser } = user
            createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                set(child(ref(db, 'users'), user.uid), newUser)
                .then(() => {
                    localStorage.setItem('uid', user.uid)
                    remove(child(ref(db, 'users'), id))
                    .then(() => navigate('/dashboard/panel'))
                })
                // const emailTemplate = emails[2]
                // const subject = emailTemplate.subject
                // const content = emailTemplate.content.replace(/\*\|Username\|\*/g, user.firstName)
                // axios.post('https://bne-backend.vercel.app/email', {
                //     email: email,
                //     subject,
                //     content
                // }).then(() => {
                // })
            })
            // .catch((error) => {
            //     const errorCode = error.code;
            //     errorCode === 'auth/email-already-in-use' && handleError('Email already exists.')
            // });
        }
    }



  return (
    <div className='auth-page'>

        <form className="container py-5" onSubmit={login}>
            <div className="row">
                <div className="col-lg-6 auth-container">
                    <Link to={'/'} className='auth-logo'>
                        <img src={logo} alt="" />
                    </Link>

                    <h1>Login</h1>
                    <h6>
                        Please enter your email address and password to log in and access your account
                    </h6>
                    <div className='auth-form'>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className='auth-form-input' placeholder='Email' required />
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className='auth-form-input' placeholder='Password' required />
                    </div>

                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-lg-6'>
                    {
                        error !== '' && <h5 className='text-danger text-center mb-3'>{error}</h5>
                    }
                    <button className={`auth-form-btn ${btnText === 'Please Wait...' && 'disabled'}`} disabled={btnText === 'Please Wait...'} type='submit'>
                        <span>{btnText}</span>
                    </button>
                </div>
            </div>
        </form>
        
    </div>
  )
}

export default CustomLogin