export const AboutUsPage = () => (
  <div
    style={{ flex: 1, textAlign: "center", color: "white"}}
  >
    About Us

    Bangla New Era is a community driven institution.

    
  </div>
);
