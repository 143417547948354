import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import countries from '../../arrays/countries.json'
import languages from '../../arrays/languages.json'
import genres from '../../arrays/genres.json'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { db, storage } from '../../firebase'
import { set, push, ref, get, child, update } from 'firebase/database'
import { getDownloadURL, uploadBytes, ref as sRef } from 'firebase/storage'


const AdminEditArtist = () => {





    //////////////////////////////////////////////
    ////////////////// BACKEND ///////////////////
    //////////////////////////////////////////////

    const { index, uid } = useParams()

    const [artists, setArtists] = useState([])

    const [name, setName] = useState('')
    const [website, setWebsite] = useState('')
    const [facebook, setFacebook] = useState('')
    const [twitter, setTwitter] = useState('')
    const [instagram, setInstagram] = useState('')
    const [youtube, setYoutube] = useState('')
    const [spotify, setSpotify] = useState('')
    const [apple, setApple] = useState('')
    const [deezer, setDeezer] = useState('')
    const [soundCloud, setSoundCloud] = useState('')

    const successBtn = useRef()

    //////////// GET USER'S RELEASES ////////////
    useEffect(() => {
        get(child(ref(db, 'users'), uid))
        .then(snapshot => {
            setArtists(snapshot.val().artists)
            initialValues(snapshot.val().artists[index])
        })
    }, [])

    const initialValues = (item) => {
        setName(item.name)
        setWebsite(item.website)
        setFacebook(item.facebook)
        setTwitter(item.twitter)
        setInstagram(item.instagram)
        setYoutube(item.youtube)
        setSpotify(item.spotify)
        setApple(item.apple)
        setDeezer(item.deezer)
        setSoundCloud(item.soundCloud)
    }

    
    const editArtist = () => {
        // var releaseKey = Math.random().toString(36).substr(2, 9);
        const artist = {
            name,
            website,
            facebook,
            twitter,
            instagram,
            youtube,
            spotify,
            apple,
            deezer,
            soundCloud
        }

        
        // set(child(ref(db, 'releases'), releaseKey), release)
        const newArtists = [...artists]
        newArtists[index] = artist
        update(child(ref(db, 'users'), uid), {
            artists: newArtists
        })
        successBtn.current.click()
    }


  return (
    <div className='dash-section'>
        <h1 className='dash-title'>Edit Artist</h1>
        <div className="row">
            <div className="col-12 mt-4">
                <div className="dash-card">
                    <div className="row">
                        <div className="col-12">
                            <h4 className='dash-card-title'>
                                <Link to={'/admin/artists'} className='title-back-link'>
                                    <i className="fi fi-rr-arrow-small-left"></i>
                                </Link>
                                Edit Artist
                            </h4>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Name</label>
                                <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder='Name' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Website</label>
                                <input value={website} onChange={(e) => setWebsite(e.target.value)} type="text" placeholder='Website' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-12 mt-4">
                            <h4 className='dash-form-heading'>Profile Information</h4>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Facebook</label>
                                <input value={facebook} onChange={(e) => setFacebook(e.target.value)} type="text" placeholder='Facebook' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Twitter</label>
                                <input value={twitter} onChange={(e) => setTwitter(e.target.value)} type="text" placeholder='Twitter' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Instagram</label>
                                <input value={instagram} onChange={(e) => setInstagram(e.target.value)} type="text" placeholder='Instagram' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Youtube</label>
                                <input value={youtube} onChange={(e) => setYoutube(e.target.value)} type="text" placeholder='Youtube' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Spotify</label>
                                <input value={spotify} onChange={(e) => setSpotify(e.target.value)} type="text" placeholder='Spotify' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Apple</label>
                                <input value={apple} onChange={(e) => setApple(e.target.value)} type="text" placeholder='Apple' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Deezer</label>
                                <input value={deezer} onChange={(e) => setDeezer(e.target.value)} type="text" placeholder='Deezer' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>SoundCloud</label>
                                <input value={soundCloud} onChange={(e) => setSoundCloud(e.target.value)} type="text" placeholder='SoundCloud' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="dash-form-btns">
                                <button 
                                    className='dash-form-btn' 
                                    onClick={editArtist}
                                >Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        
        <button ref={successBtn} data-bs-target="#successModal" data-bs-toggle="modal" hidden></button>
        <div className="modal fade" id="successModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="successModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/admin/artists">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Artist Updated Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdminEditArtist