import React, { useRef, useState } from 'react'
import logo from "../assets/logo.svg";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import '../sass/auth.scss'
import { auth } from '../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';

const ResetPassword = () => {

    const [email, setEmail] = useState('')

    const successBtn = useRef()

    const [error, setError] = useState('')

    const handleError = (errorMsg) => {
        setError(errorMsg)
        setTimeout(() => {
            setError('')
        }, 3000);
    }

    ///////// RESET PASSWORD //////////
    const handleResetPassword = (e) => {
        e.preventDefault()
        sendPasswordResetEmail(auth, email)
        .then(() => {
            successBtn.current.click()
        })
            .catch((error) => {
            handleError(error);
        });
    };


  return (
    <div className='auth-page'>

        <form className="container py-5" onSubmit={handleResetPassword}>
            <div className="row">
                <div className="col-lg-6 auth-container">
                    <Link to={'/'} className='auth-logo'>
                        <img src={logo} alt="" />
                    </Link>

                    <h1>Reset Password</h1>
                    <h6>
                        Provide your email, and we'll send you a password reset link right away.
                    </h6>
                    <div className='auth-form'>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className='auth-form-input' placeholder='Your Email Address' required />
                    </div>

                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-lg-6'>
                    {
                        error !== '' && <h5 className='text-danger text-center mb-3'>{error}</h5>
                    }
                    <button className='auth-form-btn' type='submit'>
                        <span>Send Reset Link</span>
                    </button>
                </div>
            </div>
        </form>


        <button ref={successBtn} data-bs-toggle="modal" data-bs-target="#successModal" hidden></button>
        <div className="modal fade" id="successModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="successModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a href="/resetPassword" className='success-close-btn'>
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Reset Link Sent to {email}</h5>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default ResetPassword