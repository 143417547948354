import { child, equalTo, get, onChildAdded, orderByChild, query, ref, update } from 'firebase/database'
import React, { useEffect, useRef, useState } from 'react'
import { db } from '../../firebase'
import { Link } from 'react-router-dom'

const AdminArtists = () => {

    const [artists, setArtists] = useState([])
    const [users, setUsers] = useState([])


    useEffect(() => {
        get(ref(db, 'users'))
        .then(snapshot => {
            const usersData = snapshot.val() || {}; // Get the data from the snapshot
            const usersArray = Object.entries(usersData).map(([key, value]) => ({
                id: key, // Include the key as "id" property
                ...value // Spread the rest of the properties
            }));
            setUsers(usersArray)
            const flattenedData = usersArray.flatMap(user => 
                user.artists !== undefined && user.artists.map((artist, i) => ({
                    index: i,
                    key: user.id,
                    email: user.email,
                    name: artist.name,
                    website: artist.website
                }))
            ).filter(item => item !== false)

            setArtists(flattenedData)
        })
    }, [])


    /////////// PAGINATION ///////////
    const itemsPerPage = 25;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = artists.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(artists.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const deleteSuccess = useRef()

    const deleteArtist = (userKey, artistIndex) => {
        const targetedUser = users.find(item => item.id === userKey)
        const userArtisrs = targetedUser.artists
        const newArtists = [...userArtisrs.slice(0, artistIndex), ...userArtisrs.slice(artistIndex + 1)];
        update(child(ref(db, 'users'), userKey), {
            artists: newArtists
        }).then(() => deleteSuccess.current.click())
    }


  return (
    <div className="admin-page py-5">
        <div className='container'>
            <h1 className='dash-title'>Artists</h1>
            <div className="row">
                <div className="col-12 mt-4">
                    <div className="dash-card">
                        <div className="dash-card-heading">
                            <h2 className="dash-card-title">Artists</h2>
                        </div>
                        <div className="table-responsive mt-3">
                            <table className="table dash-table">
                                <thead>
                                    <tr>
                                        <td>User</td>
                                        <td>Name</td>
                                        <td>Website</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentItems.map((artist, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <div className="table-item">
                                                        {artist.email}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        {artist.name}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        {artist.website}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item d-flex">
                                                        <Link
                                                            to={`edit/${artist.key}/${artist.index}`} 
                                                            className="table-action-btn a-blue me-1"
                                                        >
                                                            <i className="fi fi-rr-pencil"></i>
                                                        </Link>
                                                        <i 
                                                            onClick={() => deleteArtist(artist.key, artist.index)} 
                                                            className="fi fi-rr-trash table-action-btn a-red ms-2"
                                                        ></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="table-pagination">
                            {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                            <button key={pageNumber} className={currentPage === pageNumber && 'active'} onClick={() => handlePageChange(pageNumber)}>
                                {pageNumber}
                            </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>


        </div>


        <button ref={deleteSuccess} data-bs-target="#successModal" data-bs-toggle="modal" hidden></button>
        <div className="modal fade" id="successModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="successModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/admin/artists">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Artist Deleted Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>

    </div>
  )
}

export default AdminArtists