import { child, equalTo, get, onChildAdded, orderByChild, query, ref, update } from 'firebase/database'
import React, { useEffect, useRef, useState } from 'react'
import { db } from '../../firebase'

const AdminPayouts = () => {

    const [payouts, setPayouts] = useState([])
    const [requests, setRequests] = useState([])
    const [users, setUsers] = useState([])

    const successBtn = useRef()

    useEffect(() => {
        get(ref(db, 'users'))
        .then(snapshot => {
            const usersData = snapshot.val() || {}; // Get the data from the snapshot
            const usersArray = Object.entries(usersData).map(([key, value]) => ({
                id: key, // Include the key as "id" property
                ...value // Spread the rest of the properties
            }));
            setUsers(usersArray)
            const flattenedData = usersArray.flatMap(user => 
                user.payouts !== undefined && user.payouts.map(payout => ({
                    key: user.id,
                    id: payout.id,
                    email: user.email,
                    requestDate: payout.requestDate,
                    currency: payout.currency,
                    amount: payout.amount,
                    status: payout.status
                }))
            );
            const pendingPayouts = flattenedData.filter(payout => payout.status === 'Pending')
            const sentRequests = usersArray.filter(user => user.setupRequest === true)
            // const removeLast = pendingPayouts.pop()
            setPayouts(pendingPayouts)
            setRequests(sentRequests)
        })
    }, [])


    const handleDecline = (key, id) => {
        const payoutUser = users.find(user => user.id === key)
        const updatedPayouts = payoutUser.payouts.map(payout => {
            if (payout.id === id) {
                // Update the specific object
                return { ...payout, status: "Declined" };
            }
            return payout; // Keep other objects unchanged
        });
        update(child(ref(db, 'users'), key), {
            payouts: updatedPayouts,
        }).then(() => successBtn.current.click())
    }
    const handleApprove = (key, id, amount) => {
        const date = new Date()
        const payoutUser = users.find(user => user.id === key)
        const updatedBalance = payoutUser.balance - amount
        const updatedTotalPaid = payoutUser.totalPaid + amount * 1 || amount * 1
        const updatedPayouts = payoutUser.payouts.map(payout => {
            if (payout.id === id) {
                // Update the specific object
                return { ...payout, status: "Approved", paymentDate: date.toLocaleString(), };
            }
            return payout; // Keep other objects unchanged
        });
        update(child(ref(db, 'users'), key), {
            payouts: updatedPayouts,
            balance: updatedBalance,
            totalPaid: updatedTotalPaid
        }).then(() => successBtn.current.click())
    }

    const sentSuccessBtn = useRef()
    const sentInvite = (key) => {
        update(child(ref(db, 'users'), key), {
            setupRequest: 'Sent'
        }).then(() => sentSuccessBtn.current.click())
    }

  return (
    <div className="admin-page py-5">
        <div className='container'>
            <h1 className='dash-title'>Payouts</h1>
            <div className="row">
                <div className="col-12 mt-4">
                    <div className="dash-card">
                        <div className="dash-card-heading">
                            <h2 className="dash-card-title">Payouts Requests</h2>
                        </div>
                        <div className="table-responsive mt-3">
                            <table className="table dash-table">
                                <thead>
                                    <tr>
                                        <td>User</td>
                                        <td>Request Date</td>
                                        <td>Currency</td>
                                        <td>Amount</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        payouts.map((payout, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <div className="table-item">
                                                        {payout.email}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        {payout.requestDate}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        {payout.currency}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        {payout.amount}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item d-flex">
                                                        <button 
                                                            className="table-action-btn a-red me-1"
                                                            onClick={() => handleDecline(payout.key, payout.id)}
                                                        >
                                                            <i className="fi fi-br-cross"></i>
                                                        </button>
                                                        <button 
                                                            className="table-action-btn a-green"
                                                            onClick={() => handleApprove(payout.key, payout.id, payout.amount)}
                                                        >
                                                            <i className="fi fi-br-check"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <div className="dash-card">
                        <div className="dash-card-heading">
                            <h2 className="dash-card-title">Payout Setup Invite Requests</h2>
                        </div>
                        <div className="table-responsive mt-3">
                            <table className="table dash-table">
                                <thead>
                                    <tr>
                                        <td>Name</td>
                                        <td>Email</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        requests.map((request, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <div className="table-item">
                                                        {request.firstName} {request.lastName}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        {request.email}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item d-flex">
                                                        <button 
                                                            className="table-action-btn a-green"
                                                            onClick={() => sentInvite(request.id)}
                                                        >
                                                            <i className="fi fi-br-check"></i>
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </div>


        <button ref={successBtn} data-bs-target="#successModal" data-bs-toggle="modal" hidden></button>
        <div className="modal fade" id="successModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="successModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/admin/payouts">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Payout Updated Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>

        <button ref={sentSuccessBtn} data-bs-target="#sentSuccessModal" data-bs-toggle="modal" hidden></button>
        <div className="modal fade" id="sentSuccessModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="sentSuccessModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/admin/payouts">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Request Updated Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>

    </div>
  )
}

export default AdminPayouts