export const SubDown = ({ onClick }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={() => onClick()}
  >
    <path
      d="M16 27L16 5"
      stroke="#E4E4E7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M25 14L16 5L7 14"
      stroke="#E4E4E7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
