export const SubUp = ({ onClick }) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={() => onClick()}
  >
    <path
      d="M16 5V27"
      stroke="#F2F0FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 18L16 27L25 18"
      stroke="#F2F0FF"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
