import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Line } from 'react-chartjs-2'
import { Chart as ChartJS } from 'chart.js/auto'
import { child, get, ref } from 'firebase/database'
import { db } from '../../firebase'

const Panel = () => {

  const uid = localStorage.getItem('uid')

  const [releases, setReleases] = useState([])
  const [artists, setArtists] = useState(0)
  const [earnings, setEarnings] = useState(0)

  useEffect(() => {
    get(child(ref(db, 'users'), uid))
    .then(snapshot => {
      snapshot.val().releases && setReleases(snapshot.val().releases.slice(0, 5))
      snapshot.val().artists && setArtists(snapshot.val().artists.length)
      snapshot.val().totalPaid && setEarnings(snapshot.val().totalPaid.toFixed(0))
    })
  }, [])

  const truncateString = (inputString) => {
    if (inputString.length <= 10) {
      return inputString;
    } else {
      return inputString.slice(0, 10) + "...";
    }
  }

  const data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'Recent Statements',
        data: [3000, 4200, 2800, 5500, 4800, 6200, 7500],
        borderColor: '#337CCF',
        borderWidth: 2,
        fill: false,
        pointRadius: 0,
        lineTension: .3
      },
    ],
  };

  return (
    <div className='dash-section'>
        <h1 className='dash-title'>Dashboard</h1>
        <div className="row">
          <div className="col-lg-4 col-md-6 mt-3">
              <div className="dash-card dash-widget">
                <div className="dash-widget-left">
                  <h1>${earnings}</h1>
                  <h5>Total Earnings</h5>
                </div>
                <div className="dash-widget-right">
                  <i class="fi fi-sr-donate"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-3">
              <div className="dash-card dash-widget">
                <div className="dash-widget-left">
                  <h1>{releases.length}</h1>
                  <h5>Total Releases</h5>
                </div>
                <div className="dash-widget-right">
                  <i class="fi fi-ss-rocket-lunch"></i>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-3">
              <div className="dash-card dash-widget">
                <div className="dash-widget-left">
                  <h1>{artists}</h1>
                  <h5>Total Artists</h5>
                </div>
                <div className="dash-widget-right">
                  <i class="fi fi-ss-music-alt"></i>
                </div>
              </div>
            </div>
        </div>
        <div className="row">
          {/* <div className="col-lg-7 mt-4">
            <div className="dash-card">
              <div className="dash-card-heading mb-3">
                  <h2 className="dash-card-title">Recent Statements</h2>
              </div>
              <Line data={data} />
            </div>
          </div> */}
          <div className="col-lg-6 mt-4">
            <div className="dash-card">
              <div className="dash-card-heading">
                  <h2 className="dash-card-title">Latest Releases</h2>
              </div>
              <div className="l-releases">
                {
                  releases.map((release, i) => (
                    <div className="l-release" key={i}>
                      <img src={release.artwork} alt="" />
                      <h6>{truncateString(release.title)}</h6>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Panel