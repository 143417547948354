export const Circle = () => (
  <svg
    width="281"
    height="116"
    viewBox="0 0 281 116"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="circle"
  >
    <path
      d="M280.904 141.5C280.904 219.648 218.105 283 140.64 283C63.1739 283 0.375488 219.648 0.375488 141.5C0.375488 63.3517 63.1739 0 140.64 0C218.105 0 280.904 63.3517 280.904 141.5ZM6.38814 141.5C6.38814 216.298 66.4946 276.934 140.64 276.934C214.785 276.934 274.891 216.298 274.891 141.5C274.891 66.7017 214.785 6.06563 140.64 6.06563C66.4946 6.06563 6.38814 66.7017 6.38814 141.5Z"
      fill="url(#paint0_linear_24_3557)"
    />
    <path
      d="M267.31 141.5C267.31 212.141 210.598 269.406 140.639 269.406C70.6814 269.406 13.9692 212.141 13.9692 141.5C13.9692 70.8595 70.6814 13.594 140.639 13.594C210.598 13.594 267.31 70.8595 267.31 141.5ZM19.3992 141.5C19.3992 209.113 73.6803 263.923 140.639 263.923C207.599 263.923 261.88 209.113 261.88 141.5C261.88 73.8876 207.599 19.0769 140.639 19.0769C73.6803 19.0769 19.3992 73.8876 19.3992 141.5Z"
      fill="url(#paint1_linear_24_3557)"
    />
    <path
      d="M254.334 143.354C254.334 206.828 203.431 258.284 140.64 258.284C77.848 258.284 26.9453 206.828 26.9453 143.354C26.9453 79.8794 77.848 28.4235 140.64 28.4235C203.431 28.4235 254.334 79.8794 254.334 143.354ZM31.819 143.354C31.819 204.107 80.5396 253.357 140.64 253.357C200.74 253.357 249.46 204.107 249.46 143.354C249.46 82.6004 200.74 33.3501 140.64 33.3501C80.5396 33.3501 31.819 82.6004 31.819 143.354Z"
      fill="url(#paint2_linear_24_3557)"
    />
    <path
      d="M241.976 141.191C241.976 197.328 196.468 242.836 140.33 242.836C84.1934 242.836 38.6853 197.328 38.6853 141.191C38.6853 85.054 84.1934 39.5459 140.33 39.5459C196.468 39.5459 241.976 85.054 241.976 141.191ZM43.0425 141.191C43.0425 194.922 86.5998 238.479 140.33 238.479C194.061 238.479 237.618 194.922 237.618 141.191C237.618 87.4604 194.061 43.9031 140.33 43.9031C86.5998 43.9031 43.0425 87.4604 43.0425 141.191Z"
      fill="url(#paint3_linear_24_3557)"
    />
    <path
      d="M227.146 141.191C227.146 189.138 188.277 228.007 140.331 228.007C92.3837 228.007 53.5151 189.138 53.5151 141.191C53.5151 93.2442 92.3837 54.3756 140.331 54.3756C188.277 54.3756 227.146 93.2442 227.146 141.191ZM57.2366 141.191C57.2366 187.083 94.4391 224.285 140.331 224.285C186.222 224.285 223.425 187.083 223.425 141.191C223.425 95.2996 186.222 58.0971 140.331 58.0971C94.4391 58.0971 57.2366 95.2996 57.2366 141.191Z"
      fill="url(#paint4_linear_24_3557)"
    />
    <path
      d="M212.934 141.191C212.934 181.289 180.428 213.795 140.33 213.795C100.233 213.795 67.7268 181.289 67.7268 141.191C67.7268 101.093 100.233 68.5874 140.33 68.5874C180.428 68.5874 212.934 101.093 212.934 141.191ZM70.8391 141.191C70.8391 179.57 101.951 210.683 140.33 210.683C178.71 210.683 209.822 179.57 209.822 141.191C209.822 102.812 178.71 71.6997 140.33 71.6997C101.951 71.6997 70.8391 102.812 70.8391 141.191Z"
      fill="url(#paint5_linear_24_3557)"
    />
    <path
      d="M203.048 141.191C203.048 175.829 174.968 203.908 140.331 203.908C105.693 203.908 77.6133 175.829 77.6133 141.191C77.6133 106.553 105.693 78.4739 140.331 78.4739C174.968 78.4739 203.048 106.553 203.048 141.191ZM80.3018 141.191C80.3018 174.344 107.178 201.22 140.331 201.22C173.483 201.22 200.359 174.344 200.359 141.191C200.359 108.038 173.483 81.1624 140.331 81.1624C107.178 81.1624 80.3018 108.038 80.3018 141.191Z"
      fill="url(#paint6_linear_24_3557)"
    />
    <path
      d="M187.6 141.191C187.6 169.004 165.054 191.55 137.241 191.55C109.429 191.55 86.882 169.004 86.882 141.191C86.882 113.379 109.429 90.832 137.241 90.832C165.054 90.832 187.6 113.379 187.6 141.191ZM89.0407 141.191C89.0407 167.812 110.621 189.392 137.241 189.392C163.861 189.392 185.442 167.812 185.442 141.191C185.442 114.571 163.861 92.9908 137.241 92.9908C110.621 92.9908 89.0407 114.571 89.0407 141.191Z"
      fill="url(#paint7_linear_24_3557)"
    />
    <path
      d="M175.86 143.045C175.86 164.374 158.57 181.664 137.241 181.664C115.912 181.664 98.6219 164.374 98.6219 143.045C98.6219 121.716 115.912 104.426 137.241 104.426C158.57 104.426 175.86 121.716 175.86 143.045ZM100.277 143.045C100.277 163.459 116.827 180.008 137.241 180.008C157.655 180.008 174.204 163.459 174.204 143.045C174.204 122.631 157.655 106.081 137.241 106.081C116.827 106.081 100.277 122.631 100.277 143.045Z"
      fill="url(#paint8_linear_24_3557)"
    />
    <path
      d="M170.299 143.354C170.299 161.441 155.637 176.103 137.55 176.103C119.463 176.103 104.801 161.441 104.801 143.354C104.801 125.267 119.463 110.605 137.55 110.605C155.637 110.605 170.299 125.267 170.299 143.354ZM106.205 143.354C106.205 160.665 120.239 174.699 137.55 174.699C154.861 174.699 168.895 160.665 168.895 143.354C168.895 126.042 154.861 112.009 137.55 112.009C120.239 112.009 106.205 126.042 106.205 143.354Z"
      fill="url(#paint9_linear_24_3557)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_24_3557"
        x1="0.375488"
        y1="0"
        x2="283.365"
        y2="280.517"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0A35C2" />
        <stop offset="1" stopColor="#0368FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_24_3557"
        x1="13.9692"
        y1="13.594"
        x2="269.769"
        y2="266.923"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0A35C2" />
        <stop offset="1" stopColor="#0368FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_24_3557"
        x1="26.9453"
        y1="28.4235"
        x2="256.792"
        y2="255.799"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0A35C2" />
        <stop offset="1" stopColor="#0368FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_24_3557"
        x1="38.6853"
        y1="39.5459"
        x2="241.976"
        y2="242.836"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0A35C2" />
        <stop offset="1" stopColor="#0368FF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_24_3557"
        x1="53.5151"
        y1="54.3756"
        x2="227.146"
        y2="228.007"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0A35C2" />
        <stop offset="1" stopColor="#0368FF" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_24_3557"
        x1="67.7268"
        y1="68.5874"
        x2="212.934"
        y2="213.795"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0A35C2" />
        <stop offset="1" stopColor="#0368FF" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_24_3557"
        x1="77.6133"
        y1="78.4739"
        x2="203.048"
        y2="203.908"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0A35C2" />
        <stop offset="1" stopColor="#0368FF" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_24_3557"
        x1="86.882"
        y1="90.832"
        x2="187.6"
        y2="191.55"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0A35C2" />
        <stop offset="1" stopColor="#0368FF" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_24_3557"
        x1="98.6219"
        y1="104.426"
        x2="175.86"
        y2="181.664"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0A35C2" />
        <stop offset="1" stopColor="#0368FF" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_24_3557"
        x1="104.801"
        y1="110.605"
        x2="170.299"
        y2="176.103"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0A35C2" />
        <stop offset="1" stopColor="#0368FF" />
      </linearGradient>
    </defs>
  </svg>
);
