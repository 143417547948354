import React from "react";
import { Link, useLocation } from "react-router-dom";
import img_logo from "../../assets/logo.png";

const Footer = () => {
  const location = useLocation();
  const currentRouter = location.pathname;
  return (
    <div className="footer-container">
      {currentRouter === "/home" ||
      currentRouter === "/" ||
      currentRouter === "/curate" ||
      currentRouter === "/privacypolicy" ||
      currentRouter === "/contactus" ||
      currentRouter === "/aboutus" ? (
        <ul>
          <li>
            <img alt="logo" src={img_logo} width="42" height="20" />
          </li>
          <li>
            <Link to="/aboutus">About Us</Link>
          </li>
          <li>
            <a href="/contactus">
              Contact Us
            </a>
          </li>
          <li>
            <Link to="/privacypolicy">Privacy Policy</Link>
          </li>
        </ul>
      ) : (
        <ul>
          <li>
            <img alt="logo" src={img_logo} width="42" height="20" />
          </li>
          <li><Link to={'/'}>Home</Link></li>
          <li><Link to={'/curate'}>Playlists</Link></li>
          <li><Link to={'/distribute'}>Distribute</Link></li>
          <li><a href={'http://bne.live/'}>Celebrate</a></li>
        </ul>
      )}
      <p>© BNE 2023 • All rights reserved</p>
    </div>
  );
};

export default Footer;
