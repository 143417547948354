import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import amazon from '../../assets/amazon.png'
import spotify from '../../assets/spotify.png'
import apple from '../../assets/apple.png'
import { child, get, ref } from 'firebase/database'
import { db } from '../../firebase'

const ReleaseDetails = () => {

    const { id } = useParams();
    const uid = localStorage.getItem('uid')
    const [release, setRelease] = useState({})

    useEffect(() => {
        get(child(ref(db, 'users'), uid))
        .then(snapshot => {
            setRelease(snapshot.val().releases[id])
            console.log(snapshot.val().releases[id])
        })
    }, [])
    
  return (
    <div className='dash-section'>
        <h1 className='dash-title'>Releases</h1>
        <div className="row">
            <div className="col-12 mt-4">
                <div className="dash-card">
                    <div className="dash-card-heading">
                        <h2 className="dash-card-title">
                            <Link to={'/dashboard/releases'} className='title-back-link'>
                                <i className="fi fi-rr-arrow-small-left"></i>
                            </Link>
                            {release.title}
                        </h2>
                    </div>
                    <div className="release-details">
                        <img className='release-details-img' src={release.artwork} alt="" />
                        <div className="release-details-items">
                            <div className="release-details-item">
                                <h6>Label</h6><span>{release.label}</span>
                            </div>
                            <div className="release-details-item">
                                <h6>UPC</h6><span>{release.upc}</span>
                            </div>
                            <div className="release-details-item">
                                <h6>Copyright Line</h6><span>{release.copyrightYear} {release.copyrightLine}</span>
                            </div>
                            <div className="release-details-item">
                                <h6>Publishing Line</h6><span>{release.publishingYear} {release.publishingLine}</span>
                            </div>
                            <div className="release-details-item">
                                <h6>Catalogue Number</h6><span>BL281231</span>
                            </div>
                            <div className="release-details-item">
                                <h6>Genre #1</h6><span>{release.genre}</span>
                            </div>
                            <div className="release-details-item">
                                <h6>Artists</h6>
                                <span>
                                    {
                                        release.artists && release.artists.map(artist => (
                                            <>
                                                {artist.name} <br />
                                            </>
                                        ))
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="release-details-items">
                            <div className="release-details-item">
                                <h6>Explicit Lyrics</h6><span>No</span>
                            </div>
                            <div className="release-details-item">
                                <h6>Duration</h6><span>01:00:02</span>
                            </div>
                            <div className="release-details-item">
                                <h6>Creation Date</h6><span>{release.originalDate}</span>
                            </div>
                            <div className="release-details-item">
                                <h6>Release Date</h6><span>{release.digitalDate}</span>
                            </div>
                            <div className="release-details-item">
                                <h6>Distribution Type</h6><span>{release.distribution}</span>
                            </div>
                            <div className="release-details-item">
                                <h6>Status</h6><span>{release.status}</span>
                            </div>
                        </div>
                    </div>
                    <div className="release-stores">
                        <a href="#">
                            <img src={amazon} alt="" />
                        </a>
                        <a href="#">
                            <img src={spotify} alt="" />
                        </a>
                        <a href="#">
                            <img src={apple} alt="" />
                        </a>
                    </div>
                    <h2 className="dash-card-title mt-4">Tracks</h2>
                    <div className="table-responsive">
                        <table className="table dash-table">
                            <thead>
                                <tr>
                                    <td>Title</td>
                                    <td>Artist</td>
                                    <td>Authors</td>
                                    <td>Composers</td>
                                    <td>Duration</td>
                                    <td>Genre #1</td>
                                    <td>ISRC</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    release.tracks && release.tracks.map((track, i) => (
                                        <tr key={i}>
                                            <td>
                                                <div className="table-item">
                                                    {track.title}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-item">
                                                    {
                                                        track.artists && track.artists.map((artist, i) => (
                                                            <div key={i}>
                                                                {artist.role !== 'Lyricist' && artist.role !== 'Composer' && artist.name}
                                                                {/* {i < track.artists.length - 1 && ", "} */}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-item">
                                                    {
                                                        track.artists && track.artists.map((artist, i) => (
                                                            <div key={i}>
                                                                {artist.role === 'Lyricist' && artist.name}
                                                                {/* {i < track.artists.length - 1 && ", "} */}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-item">
                                                    {
                                                        track.artists && track.artists.map((artist, i) => (
                                                            <div key={i}>
                                                                {artist.role === 'Composer' && artist.name}
                                                                {/* {i < track.artists.length - 1 && ", "} */}
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-item">
                                                    {track.duration}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-item">
                                                    {track.genre}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-item">
                                                    {track.isrc}
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ReleaseDetails