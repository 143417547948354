import { child, equalTo, get, onChildAdded, orderByChild, query, ref, set, update } from 'firebase/database'
import React, { useEffect, useRef, useState } from 'react'
import { db } from '../../firebase'
import Papa from 'papaparse';
import { generate } from "random-words";
import emails from '../../arrays/emails.json'
import axios from 'axios';
import { Link } from 'react-router-dom';

const AdminUsers = () => {

    const [users, setUsers] = useState([])
    const [uploadMessage, setUploadMessage] = useState('Upload Claims')
    const [uploadReportsMessage, setUploadReportsMessage] = useState('Upload Reports')
    const [uploadReleasesMessage, setUploadReleasesMessage] = useState('Upload Releases')
    const claimsSuccess = useRef()
    const reportsSuccess = useRef()

    useEffect(() => {
        get(ref(db, 'users'))
        .then(snapshot => {
            const usersData = snapshot.val() || {}; // Get the data from the snapshot
            const usersArray = Object.entries(usersData).map(([key, value]) => ({
                id: key, // Include the key as "id" property
                ...value // Spread the rest of the properties
            }));
            setUsers(usersArray);
        })
    }, [])
    

    /////////// PAGINATION ///////////
    const itemsPerPage = 25;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = users.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(users.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const [csvData, setCsvData] = useState([]);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        setUploadMessage('Please Wait...')

        if (file) {
        Papa.parse(file, {
            complete: (result) => {
            // Parse CSV data and store it in state
            setCsvData(result.data)
            console.log(result.data)
            updateUserClaims(result.data)
            },
            header: true, // Set this to true if your CSV file has a header row
        });
        }
    }


    const updateUserClaims = (claims) => {
        const usersRef = ref(db, "users");
      
        return get(usersRef)
          .then((usersSnapshot) => {
            if (usersSnapshot.exists()) {
              const usersData = usersSnapshot.val();
      
              // Iterate through each user synchronously using Object.entries
              Object.entries(usersData).forEach(([userId, user]) => {
                const { prefix } = user;
                const claimsToUpdate = [];
      
                // Iterate through your array of objects (videos)
                claims.forEach((claim) => {
                  if (claim.custom_id.startsWith(prefix) 
                    && claim.claim_type === 'AUDIO' 
                    && claim.claim_status === 'ACTIVE'
                    && claim.video_id !== '') {
                    claimsToUpdate.push({
                        url: claim.video_id,
                        views: claim.views,
                        track: claim.asset_title,
                        artist: claim.artist,
                        channel: claim.channel_display_name
                    });
                  }
                });
      
                // Update the user's claims property in Firebase
                const userClaimsRef = ref(db, `users/${userId}`);
                return update(userClaimsRef, {claims: claimsToUpdate});
              });
      
              console.log("Claims updated successfully!");
              claimsSuccess.current.click()
              setUploadMessage('Upload Claims')
            } else {
              console.log("No users found in the database.");
            }
          })
          .catch((error) => {
            console.error("Error updating claims:", error);
          });
    }



    const reportsInput = useRef()
    const reportSuccess = useRef()
    

    const sortChannels = (data) => {
        const channelRevenueMap = data.reduce((acc, report) => {
            const { channel, taxesnet_total_client_currency } = report;
            acc[channel] = (acc[channel] || 0) + taxesnet_total_client_currency * 1;
            return acc;
        }, {});
        const sortedChannels = Object.keys(channelRevenueMap).sort((a, b) => {
            return channelRevenueMap[b] - channelRevenueMap[a];
        });
        const topChannels = sortedChannels.slice(0, 4);
        const resultObjects = topChannels.map(channel => {
            return {
              channel: channel,
              totalRevenue: channelRevenueMap[channel],
              // ... other properties you want to include in the result object
            };
        });
        return resultObjects
    }

    const sortReleases = (data) => {
        const releaseRevenueMap = data.reduce((acc, report) => {
            const { release, taxesnet_total_client_currency } = report;
            acc[release] = (acc[release] || 0) + taxesnet_total_client_currency * 1;
            return acc;
        }, {});
        const sortedReleases = Object.keys(releaseRevenueMap).sort((a, b) => {
            return releaseRevenueMap[b] - releaseRevenueMap[a];
        });
        const topReleases = sortedReleases.slice(0, 4);
        const resultObjects = topReleases.map(release => {
            return {
              release: release,
              totalRevenue: releaseRevenueMap[release],
              // ... other properties you want to include in the result object
            };
        });
        return resultObjects
    }

    const sortCountries = (data) => {
        const countryRevenueMap = data.reduce((acc, report) => {
            const { country, taxesnet_total_client_currency } = report;
            acc[country] = (acc[country] || 0) + taxesnet_total_client_currency * 1;
            return acc;
        }, {});
        const sortedCountries = Object.keys(countryRevenueMap).sort((a, b) => {
            return countryRevenueMap[b] - countryRevenueMap[a];
        });
        const topCountries = sortedCountries.slice(0, 4);
        const resultObjects = topCountries.map(country => {
            return {
              country: country,
              totalRevenue: countryRevenueMap[country],
              // ... other properties you want to include in the result object
            };
        });
        return resultObjects
    }



    /////// GROUP RELEASES ///////
    const groupReleases = (reports) => {
        const transformedTracks = Object.values(
            reports.reduce((acc, report) => {
              const { release, upc, units, track: track, taxesnet_total_client_currency, isrc } = report;
              if (!acc[upc]) {
                acc[upc] = {
                  release,
                  upc,
                  revenue: 0,
                  streams: 0,
                  tracks: [],
                };
              }
              acc[upc].revenue += taxesnet_total_client_currency * 1;
              acc[upc].streams += units * 1;
              acc[upc].tracks.push({ track: track, streams: units, revenue: taxesnet_total_client_currency, isrc });
              return acc;
            }, {})
        );
        return transformedTracks
    }

    const groupTracksByISRC = (reports) => {
        const groupedReports = reports.map((report) => {
          const groupedTracks = {};
      
          report.tracks.forEach((item) => {
            const { track, streams, revenue, isrc } = item;
      
            if (!groupedTracks[isrc]) {
              groupedTracks[isrc] = { track, streams: 0, revenue: 0, isrc, share: 100 };
            }
      
            groupedTracks[isrc].streams += streams * 1;
            groupedTracks[isrc].revenue += revenue * 1;
          });
      
          return { release: report.release, streams: report.streams, revenue: report.revenue, tracks: Object.values(groupedTracks) };
        });
      
        return groupedReports;
    };

    // const groupSplits = (tracks, splits) => {
    //     const groupedTracks = [];

    //     // Iterate over each track
    //     tracks.forEach((track) => {
    //         const { isrc, taxesnet_total_client_currency } = track;

    //         // Check if the isrc already exists in groupedTracks
    //         const existingTrack = groupedTracks.find((groupedTrack) => groupedTrack.isrc === isrc);

    //         // If the isrc doesn't exist, create a new entry
    //         if (!existingTrack) {
    //             groupedTracks.push({ isrc, total_price: taxesnet_total_client_currency * 1 });
    //         } else {
    //             // If the isrc exists, update the total price
    //             existingTrack.total_price += taxesnet_total_client_currency * 1;
    //         }
    //     });

    //     groupedTracks.forEach((track) => {

    //     })
    // }


    let collabReports = []
    
    const checkTotalRecoup = (user, isrc) => {
        const entries = collabReports.filter(entry => entry.user === user && entry.isrc === isrc);
      
        // Calculate the total amount for the found entries
        const totalAmount = entries.reduce((sum, entry) => sum + entry.addedPrice * 1, 0);
      
        return totalAmount;
    };

    const remainingPrice = (splits, price, isrc) => {
        let totalPercentage = 0;
        let sharePrice = 0

        const recoupExist = splits.find(item => item.recoupment * 1 > 0 && item.track === isrc)

        // Calculate prices for each share
        // if (recoupExist && recoupExist.recoupment * 1 > checkTotalRecoup(recoupExist.user, recoupExist.track)) {
        //     const recoupPrice = Math.min(recoupExist.recoupment * 1, price)
        //     collabReports = [...collabReports, {user: recoupExist.user, addedPrice: recoupPrice, isrc, recoupPrice: recoupExist.recoupment * 1}]
        //     sharePrice = price
        // } else {
            splits.forEach(({ share, user, track }) => {
                if (track === isrc) {
                    const userShare = (share / 100) * price;
                    collabReports = [...collabReports, {user, addedPrice: userShare, recoupPrice: 0, isrc}]
                    sharePrice += userShare
                    // console.log(`${userShare} for ${user} from ${price}`);
                } else {}
            });
        // }
        
        // Calculate and log the price for the remaining percentage
        const remainingPrice = price - sharePrice;
        // console.log(`${price - sharePrice} instead of ${price}`);
        return remainingPrice
    }


    const groupCollabs = (collabArray) => {
      
        const groupedCollabs = [];
        const existingCollabs = []
        
        // Iterate over each track
        collabArray.forEach((collabUser) => {
            const { user, addedPrice, recoupPrice, isrc } = collabUser;

            // Check if the isrc already exists in groupedCollabs
            const existingUser = groupedCollabs.find((groupedUser) => groupedUser.user === user);
            
            // If the isrc doesn't exist, create a new entry
            if (!existingUser) {
                groupedCollabs.push({ user, price: addedPrice * 1, recoupPrice, isrc });
            } else {
                existingUser.price += addedPrice * 1;
            }
        });

        groupedCollabs.forEach(item => {
            users.forEach(dbuser => {
                if (dbuser.email === item.user) {
                    existingCollabs.push({user: item.user, price: ((dbuser.balance * 1) || 0) + item.price, key: dbuser.id, recoupPrice: item.recoupPrice, isrc: item.isrc})
                }
            })
        })

        return existingCollabs
    }


    const clearRecoupment = (splits, user, isrc, newRecoup) => {
        let newSplits = [...splits]
        const index = splits.findIndex(entry => entry.user === user && entry.track === isrc);
        if (index !== -1) {
          // Update the amount for the found entry
          newSplits[index].recoupment = newRecoup;
        } else {
        }

        return newSplits
    };


    ///// ORDER MONTHS /////    
    const sortArrayByMonthYear = (array) => {
        return array.sort((a, b) => {
            const [aMonth, aYear] = a.monthYear.split('/').map(Number);
            const [bMonth, bYear] = b.monthYear.split('/').map(Number);
    
            // Compare years first
            if (aYear !== bYear) {
                return aYear - bYear;
            }
    
            // If years are the same, compare months
            return aMonth - bMonth;
        });
    }




    const groupUsersTracks = (users) => {
        const groupedUsers = users.map((user) => {
            const tracksByISRC = user.tracks.reduce((accumulator, track) => {
            const existingTrack = accumulator.find((t) => t.isrc === track.isrc);

            if (existingTrack) {
                // If track with the same ISRC already exists, update the revenue
                existingTrack.revenueShare += track.revenueShare * 1;
            } else {
                // If track with the same ISRC does not exist, create a new track
                const newTrack = { 
                    isrc: track.isrc, 
                    revenueShare: track.revenueShare, 
                    title: track.title,
                    percentage: track.percentage
                };
                accumulator.push(newTrack);
            }

            return accumulator;
            }, []);

            return {
                user: user.user,
                key: user.key,
                balance: user.balance,
                tracks: tracksByISRC,
            };
        });

        return groupedUsers;
    };

    const groupUsersByEmail = (users) => {
        const groupedUsers = users.reduce((accumulator, user) => {
          const existingUser = accumulator.find((groupedUser) => groupedUser.email === user.user);
      
          if (existingUser) {
            // If user with the same email already exists in the accumulator, update the balance
            existingUser.balance += user.revenueShare * 1;
            existingUser.key = user.key
            existingUser.tracks.push({ 
                revenueShare: user.revenueShare, 
                isrc: user.isrc,
                isRecoupment: user.isRecoupment,
                percentage: user.percentage,
                title: user.title,
                user: user.user
            });
          } else {
            // If user with the same email does not exist, create a new grouped user
            const newUser = {
              key: user.key,
              email: user.user,
              balance: user.revenueShare * 1,
              tracks: [...user.inviteSplits, { 
                revenueShare: user.revenueShare, 
                isrc: user.isrc,
                title: user.title,
                isRecoupment: user.isRecoupment,
                percentage: user.percentage,
                user: user.user
            }],
            };
            accumulator.push(newUser);
          }
      
          return accumulator;
        }, []);
      
        return groupedUsers;
      };

    const groupCollaborators = (array) => {
        const newCollabs = []
        let count = 0
        users.forEach(user => {
            array.forEach(collab => {
                if (collab.user === user.email) {
                    newCollabs.push({
                        key: user.id,
                        // balance: user.balance * 1 || 0,
                        // inviteSplits: user.inviteSplits || [],
                        balance: 0,
                        inviteSplits: [],
                        ...collab
                    })
                }
            })
            count++
        })

        if (count === users.length) {
            return groupUsersByEmail(newCollabs)
        }
    }

    const calculateRevenueShares = (releases, splits) => {
        const collaborators = [];
      
        // Iterate through releases
        releases.forEach(release => {
            // Iterate through tracks in each release
            release.tracks.forEach(track => {
                
                const recoupmentUserIndex = splits.findIndex(
                    (split) => split.track === track.isrc && split.recoupment * 1 > 0
            );
          
            if (recoupmentUserIndex !== -1) {
                const recoupmentUser = splits[recoupmentUserIndex];
                const recoupmentAmount = Math.min(recoupmentUser.recoupment * 1, track.revenue * 1);
                
                // Update the recoupment user object in the splits array
                splits[recoupmentUserIndex] = {
                    ...recoupmentUser,
                  recoupment: recoupmentUser.recoupment - recoupmentAmount,
                };
          
                collaborators.push({
                    user: recoupmentUser.user,
                    recoupment: recoupmentUser.recoupment - recoupmentAmount,
                    revenueShare: recoupmentAmount,
                    isrc: track.isrc,
                    percentage: recoupmentUser.share,
                    title: track.track,
                    isRecoupment: true
                });

                track.revenue -= recoupmentAmount

                // const remainingAmount = track.revenue - recoupmentAmount;
                if (track.revenue > 0) {
                    const matchingSplits = splits.filter(split => split.track === track.isrc);
                    
                    // Calculate and update revenue share for each split
                    let count = 0
                    let totalShares = 0
                    let remainPercentage = 100
                    matchingSplits.forEach(split => {
                        count++
                        const revenueShare = (track.revenue * split.share) / 100;
                        totalShares += revenueShare
                        remainPercentage -= split.share * 1
                        
                        // Add collaborator to the collaborators array
                        collaborators.push({
                            user: split.user,
                            recoupment: 0,
                            revenueShare,
                            isrc: track.isrc,
                            percentage: split.share,
                            title: track.track,
                            isRecoupment: false
                        });
                    });
                    if (count === matchingSplits.length) {
                        track.revenue -= totalShares;
                        track.share = remainPercentage
                    }
                }
                
            } else {
                const matchingSplits = splits.filter(split => split.track === track.isrc);
                
                // Calculate and update revenue share for each split
                let count = 0
                let totalShares = 0
                let remainPercentage = 100
                matchingSplits.forEach(split => {
                    count++
                    const revenueShare = (track.revenue * split.share) / 100;
                    totalShares += revenueShare
                    remainPercentage -= split.share * 1
                    
                    // Add collaborator to the collaborators array
                    collaborators.push({
                        user: split.user,
                        recoupment: 0,
                        revenueShare,
                        isrc: track.isrc,
                        percentage: split.share,
                        title: track.track,
                        isRecoupment: false
                    });
                });
                if (count === matchingSplits.length) {
                    track.revenue -= totalShares;
                    track.share = remainPercentage
                }
            }
            
            
            // Find splits for the current track by matching ISRC
          });
        });
      
        // Return the updated releases array and the collaborators array
        return { releases, collaborators };
      }


    const updateReports = (reports) => {
        const usersRef = ref(db, "users");
      
        return get(usersRef)
          .then((usersSnapshot) => {
            if (usersSnapshot.exists()) {
              const usersData = usersSnapshot.val();
              let count = 0
      
              // Iterate through each user synchronously using Object.entries
              Object.entries(usersData).forEach(([userId, user]) => {
                count++
                if (user.releases) {
                    const date = new Date()
                    const monthYear = `${date.getMonth() + 1}/${date.getFullYear()}`
                    const upcs = user.releases.map(release => release.upc * 1)
                    const totalPrices = reports
                    .filter(report => upcs.includes(report.upc * 1))
                    // .reduce((total, report) => total + report.taxesnet_total_client_currency * 1, 0);

                    const totalsByMonthYear = {};

                    totalPrices.forEach(report => {
                        const startDate = new Date(report.start_date);
                        const endDate = new Date(report.end_date);

                        const startMonth = startDate.getMonth() + 1; // Adding 1 because months are 0-based
                        const startYear = startDate.getFullYear();

                        const endMonth = endDate.getMonth() + 1;
                        const endYear = endDate.getFullYear();

                        let reportMonth;

                        if (startMonth === endMonth && startYear === endYear) {
                            reportMonth = `${startMonth}/${startYear}`;
                        } else {
                            // If the start and end months are different, the majority of the report belongs to the month after the start month.
                            const nextMonth = (startMonth + 1) % 12 || 12; // Using modulo 12 to handle December (12 % 12 = 0)
                            reportMonth = `${nextMonth}/${startYear}`;
                        }

                        const monthYear = reportMonth;
                        
                        if (user.splits === undefined) {
                            totalsByMonthYear[monthYear] = (totalsByMonthYear[monthYear] || 0) + report.taxesnet_total_client_currency * 1;
                        } else {
                            totalsByMonthYear[monthYear] = (totalsByMonthYear[monthYear] || 0) + report.taxesnet_total_client_currency * 1;
                            // totalsByMonthYear[monthYear] = (totalsByMonthYear[monthYear] || 0) + remainingPrice(user.splits, report.taxesnet_total_client_currency, report.isrc);
                        }


                        // console.log(`${user.splits !== undefined && user.splits[0].track}, ${report.taxesnet_total_client_currency}`)

                    });

                    const monthlyTotalArray = Object.entries(totalsByMonthYear).map(([monthYear, totalPrice]) => ({
                        monthYear,
                        totalPrice
                    }));

                    const balance = monthlyTotalArray.reduce((total, item) => total + item.totalPrice * 1, 0)

                    const topChannels = sortChannels(totalPrices)
                    const topReleases = sortReleases(totalPrices)
                    const topCountries = sortCountries(totalPrices)

                    let groupedReports = []
                    const groupedReleases = groupReleases(totalPrices)
                    const groupedTracks = groupTracksByISRC(groupedReleases)
                    if (user.splits !== undefined) {
                        const userShares = calculateRevenueShares(groupedTracks, user.splits)
                        groupedReports = userShares.releases
                        const collabsList = groupCollaborators(userShares.collaborators)
                        collabsList.forEach(collabUser => {
                            update(child(ref(db, 'users'), collabUser.key), {
                                balance: collabUser.balance,
                                inviteSplits: collabUser.tracks
                            })
                        })  
                    } else {
                        groupedReports = groupedTracks
                    }
                    
                    const sortedArray = sortArrayByMonthYear(monthlyTotalArray)
                    ///////////
                    update(child(ref(db, 'users'), userId), {
                        reports: sortedArray,
                        sorting: {
                            channels: topChannels,
                            releases: topReleases,
                            countries: topCountries
                        },
                        groupedReleases: groupedReports,
                        balance: balance,
                        splits: user.splits || []
                    })
                    // .then(() => {
                    //     reportSuccess.current.click()
                    //     setUploadReportsMessage('Upload Reports')
                    // })
                }
                if (count === Object.entries(usersData).length) {
                    reportSuccess.current.click()
                }
              });
            } else {
              console.log("No users found in the database.");
            }
          })
          .catch((error) => {
            console.error("Error updating reports:", error);
          });
    }



    const handleReportsUpload = (event) => {
        const file = event.target.files[0];
        setUploadReportsMessage('Please Wait...')
        if (file) {
        Papa.parse(file, {
            complete: (result) => {
            // Parse CSV data and store it in state
            // console.log(result.data)
            // calculateMonthlyTotals(result.data)
            updateReports(result.data)
            },
            header: true, // Set this to true if your CSV file has a header row
        });
        }
    }


    ///////////////////////////////////////////////////
    /////////////// RELEASES UPLOADER /////////////////
    ///////////////////////////////////////////////////
    const releasesSuccess = useRef()

    const handleArtists = (artistsString) => {
        const arrayObjects = artistsString.split('|').map(entry => {
            const [role, name] = entry.split(':');
            return { role, name };
        });
        return arrayObjects
    }

    const groupReleasesByUser = (objects) => {
        const groupedObjects = {};
      
        objects.forEach((obj) => {
          const { user, ...rest } = obj;
      
          if (!groupedObjects[user]) {
            groupedObjects[user] = { user, releases: [] };
          }
      
          groupedObjects[user].releases.push(rest);
        });
      
        const grouppedReleases = Object.values(groupedObjects);
        // console.log(grouppedReleases)

        grouppedReleases.forEach((item) => {
            const userWithEmail = users.find((user) => user.email === item.user);
        
            if (userWithEmail) {
              userWithEmail.releases = [...userWithEmail.releases || [], ...item.releases];
              update(child(ref(db, 'users'), userWithEmail.id), {
                releases: userWithEmail.releases
              })
            }
        });

        releasesSuccess.current.click()

    }

    const handleGroupReleases = (releasesArray) => {
        const groupedReleases = releasesArray.reduce((acc, release) => {
          
            // Check if UPC exists in the accumulator
            if (!acc[release['UPC']]) {

                acc[release['UPC']] = {
                    user: release['User email'],
                    title: release['Release title'],
                    tracks: [],
                    upc: release['UPC'],
                    artwork: release['Release cover'],
                    status: 'Live',
                    language: release['Language'],
                    primaryGenre: release['Primary genre'],
                    secondaryGenre: release['Secondary genre'],
                    artists: handleArtists(release['Release artists']),
                    countries: ['Worldwide'],
                    digitalDate: release['Digital Release Date'],
                    originalDate: release['Original Release Date'],
                    copyrightYear: release['Copyright Year'],
                    copyrightLine: release['Copyright Line'],
                    publishingYear: release['Publishing Year'],
                    publishingLine: release['Publishing Line'],
                    label: release['Label'],
                    distribution: 'All'
                };

            }
          
            // Add track and artist to the respective arrays
            const pushedTrack = {
                title: release['Track title'],
                trackLanguage: release['Track language'],
                isrc: release['ISRC code'],
                clipStart: release['Start point time'],
                genre: release['Track primary genre'],
                explicit: release['Explicit lyrics'] === 'Explicit' ? 'Yes' : 'No',
                artists: handleArtists(release['Track artists'])
            }
            acc[release['UPC']].tracks.push(pushedTrack);
            // acc[upc].artists.push(artists);
          
            return acc;
          }, {});
          
          // Convert the grouped releases object to an array
          const groupedReleasesArray = Object.values(groupedReleases);
          
          groupReleasesByUser(groupedReleasesArray);
    }

    const handleReleasesUpload = (event) => {
        const file = event.target.files[0];
        setUploadReleasesMessage('Please Wait...')
        if (file) {
        Papa.parse(file, {
            complete: (result) => {
                handleGroupReleases(result.data)
            },
            header: true, // Set this to true if your CSV file has a header row
        });
        }
    }


    /////////////////////////////////////////////
    ////////////// UPLOAD ARTISTS ///////////////
    /////////////////////////////////////////////
    const [uploadArtistsMessage, setUploadArtistsMessage] = useState('Upload Artists')
    const artistsSuccess = useRef()

    const updateUserArtists = (objects) => {
        objects.forEach((item) => {
            const userWithEmail = users.find((user) => user.email === item['User_Email']);
        
            if (userWithEmail) {
                const newArtist = {
                    name: item['Name'],
                    website: item['Website'],
                    facebook: item['Facebook'],
                    instagram: item['Instagram'],
                    youtube: item['Youtube'],
                    twitter: item['Twitter'],
                    apple: item['Apple'],
                    spotify: item['Spotify'],
                    deezer: item['Deezer'],
                }
              userWithEmail.artists = [...userWithEmail.artists || [], newArtist];
              update(child(ref(db, 'users'), userWithEmail.id), {
                artists: userWithEmail.artists
              })
            }
        });

        artistsSuccess.current.click()
    }
    
    const handleArtistsUpload = (event) => {
        const file = event.target.files[0];
        setUploadArtistsMessage('Please Wait...')
        if (file) {
        Papa.parse(file, {
            complete: (result) => {
                updateUserArtists(result.data)
            },
            header: true, 
        });
        }
    }


    /////////////////////////////////////////////
    ////////////// UPLOAD LABELS ///////////////
    /////////////////////////////////////////////
    const [uploadLabelsMessage, setUploadLabelsMessage] = useState('Upload Labels')
    const labelsSuccess = useRef()

    const updateUserLabels = (objects) => {
        objects.forEach((item) => {
            const userWithEmail = users.find((user) => user.email === item['User_Email']);
        
            if (userWithEmail) {
                const newLabel = {
                    name: item['Name'],
                    logo: item['Logo'],
                    country: ''
                }
              userWithEmail.labels = [...userWithEmail.labels || [], newLabel];
              update(child(ref(db, 'users'), userWithEmail.id), {
                labels: userWithEmail.labels
              })
            }
        });

        labelsSuccess.current.click()
    }
    
    const handleLabelsUpload = (event) => {
        const file = event.target.files[0];
        setUploadLabelsMessage('Please Wait...')
        if (file) {
        Papa.parse(file, {
            complete: (result) => {
                updateUserLabels(result.data)
            },
            header: true, 
        });
        }
    }



    /////////////////////////////////////////////
    ////////////// UPLOAD USERS ///////////////
    /////////////////////////////////////////////
    const [uploadUsersMessage, setUploadUsersMessage] = useState('Upload Users')
    const [existingUser, setExistingUser] = useState('')
    const usersSuccess = useRef()
    const usersError = useRef()
    

    const isEmailInArray = (email) => {
        return users.some(obj => obj.email === email);
    }

    const generateRandomId = () => {
        const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let result = "";

        for (let i = 0; i < 20; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            result += charset.charAt(randomIndex);
        }

        return result;
    }

    const passwordGenerator = () => {
        const randomNumber = Math.floor(Math.random() * 9000) + 1000;
        const word = `${generate()}-${generate()}-${randomNumber}`
        const randomPassword = word.slice(0, 1).toUpperCase().concat(word.slice(1))
        return randomPassword
    }

    const handleUsers = (objects) => {
        usersSuccess.current.click()
        objects.forEach(user => {
            // setTimeout(() => { 
                
                
                const userExist = isEmailInArray(user['Email'])
                if (userExist) {
                    // setExistingUser(user['Email'])
                    // usersError.current.click()
                } else {
                    const generatedPasword = passwordGenerator()
                    const randomID = generateRandomId()
                    const newUser = {
                        firstName: user['First_name'] || '',
                        lastName: user['Last_name'] || '',
                        email: user['Email'],
                        prefix: user['CustomID'],
                        labelsElligible: user['Can_add_labels'] === 'Yes' ? true : false,
                        paymentSetup: user['Payable'] === 'Yes' ? true : false,
                        accountType: 'Artist',
                        country: user['Country'] || '',
                        state: user['State'] || '',
                        city: user['City'] || '',
                        street1: user['Street_1'] || '',
                        street2: user['Street_2'] || '',
                        zip: user['Zip'] || '',
                        phone: user['Contact_phone'] || '',
                        company: user['Company_name'] || '',
                        password: generatedPasword
                    }
                    // recipients = [...recipients, user['Email']]
                    console.log(newUser, randomID)
                    set(child(ref(db, 'users'), randomID), newUser)
                    const emailTemplate = emails[3]
                    const subject = emailTemplate.subject
                    const content = emailTemplate.content.replace(/\*\|Name\|\*/g, user['First_name'] || 'there').replace(/\*\|Email\|\*/g, user['Email']).replace(/\*\|Password\|\*/g, generatedPasword).replace(/\*\|LoginID\|\*/g, randomID)
                    axios.post('https://bne-backend.vercel.app/email', {
                        email: user['Email'],
                        subject,
                        content
                    })
                }
                    
        })
    }
    
    const handleUsersUpload = (event) => {
        const file = event.target.files[0];
        setUploadUsersMessage('Please Wait...')
        if (file) {
        Papa.parse(file, {
            complete: (result) => {
                handleUsers(result.data)
            },
            header: true, 
        });
        }
    }


    /////////////////////////////////////////////
    ////////////// PAYABLE TOGGLE ///////////////
    /////////////////////////////////////////////
    const handlePayable = (id, value) => {
        setUsers(prevUsers => (
            prevUsers.map(user => (
              user.id === id ? { ...user, paymentSetup: value } : user
            ))
        ));
        update(child(ref(db, 'users'), id), {
            paymentSetup: value,
            setupRequest: false
        })
    }
    
    const handleLabels = (id, value) => {
        setUsers(prevUsers => (
            prevUsers.map(user => (
              user.id === id ? { ...user, labelsElligible: value } : user
            ))
        ));
        update(child(ref(db, 'users'), id), {
            labelsElligible: value
        })
    }


  return (
    <div className="admin-page py-5">
        <div className='container'>
            <div className="row">
                <div className="col-lg-4 mt-4">
                    <div className="dash-form-file">
                        <i className="fi fi-rr-cloud-upload"></i>
                        {uploadMessage}
                        <input type="file" onChange={handleFileUpload} />
                        <button data-bs-toggle="modal" data-bs-target="#claimsModal" ref={claimsSuccess} hidden></button>
                    </div>
                </div>
                <div className="col-lg-4 mt-4">
                    <div className="dash-form-file">
                        <i className="fi fi-rr-cloud-upload"></i>
                        {uploadReportsMessage}
                        <input type="file" onChange={handleReportsUpload} />
                        <button data-bs-toggle="modal" data-bs-target="#reportsModal" ref={reportsSuccess} hidden></button>
                    </div>
                </div>
                <div className="col-lg-4 mt-4">
                    <div className="dash-form-file">
                        <i className="fi fi-rr-cloud-upload"></i>
                        {uploadReleasesMessage}
                        <input type="file" onChange={handleReleasesUpload} />
                        <button data-bs-toggle="modal" data-bs-target="#releasesModal" ref={releasesSuccess} hidden></button>
                    </div>
                </div>
                <div className="col-lg-4 mt-4">
                    <div className="dash-form-file">
                        <i className="fi fi-rr-cloud-upload"></i>
                        {uploadArtistsMessage}
                        <input type="file" onChange={handleArtistsUpload} />
                        <button data-bs-toggle="modal" data-bs-target="#artistsModal" ref={artistsSuccess} hidden></button>
                    </div>
                </div>
                <div className="col-lg-4 mt-4">
                    <div className="dash-form-file">
                        <i className="fi fi-rr-cloud-upload"></i>
                        {uploadLabelsMessage}
                        <input type="file" onChange={handleLabelsUpload} />
                        <button data-bs-toggle="modal" data-bs-target="#labelsModal" ref={labelsSuccess} hidden></button>
                    </div>
                </div>
                <div className="col-lg-4 mt-4">
                    <div className="dash-form-file">
                        <i className="fi fi-rr-cloud-upload"></i>
                        {uploadUsersMessage}
                        <input type="file" onChange={handleUsersUpload} />
                        <button data-bs-toggle="modal" data-bs-target="#usersModal" ref={usersSuccess} hidden></button>
                        <button data-bs-toggle="modal" data-bs-target="#usersErrorModal" ref={usersError} hidden></button>
                    </div>
                </div>
                <div className="col-12 mt-4">
                    <div className="dash-card">
                        <div className="dash-card-heading">
                            <h2 className="dash-card-title">Users</h2>
                        </div>
                        <div className="table-responsive mt-3">
                            <table className="table dash-table">
                                <thead>
                                    <tr>
                                        <td>Name</td>
                                        <td>Email</td>
                                        <td>Type</td>
                                        <td>Payable</td>
                                        <td>Labels</td>
                                        <td>Action</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentItems.map((user, i) => (
                                            <tr key={i}>
                                                <td>
                                                    <div className="table-item">
                                                        {user.firstName} {user.lastName}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        {user.email}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        {user.accountType}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        <div 
                                                            className={`toggle ${user.paymentSetup === true && 'active'}`}
                                                            onClick={() => handlePayable(user.id, user.paymentSetup === true ? false : true)}
                                                        >
                                                            <div></div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item">
                                                        <div 
                                                            className={`toggle ${user.labelsElligible === true && 'active'}`}
                                                            onClick={() => handleLabels(user.id, user.labelsElligible === true ? false : true)}
                                                        >
                                                            <div></div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="table-item d-flex">
                                                        <Link 
                                                            className="table-action-btn a-blue me-1"
                                                            to={`/admin/users/${user.id}`}
                                                        >
                                                            <i className="fi fi-rr-pencil"></i>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className="table-pagination">
                            {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                            <button key={pageNumber} className={currentPage === pageNumber && 'active'} onClick={() => handlePageChange(pageNumber)}>
                                {pageNumber}
                            </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>


        </div>

        <input type="file" onChange={handleReportsUpload} ref={reportsInput} hidden/>
        <button data-bs-toggle="modal" data-bs-target="#reportsModal" ref={reportSuccess} hidden></button>


        <div className="modal fade" id="claimsModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="claimsModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/admin/users">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Claims Updated Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="modal fade" id="reportsModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="reportsModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/admin/users">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Reports Updated Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>
        
        <button data-bs-toggle="modal" data-bs-target="#releasesModal" ref={releasesSuccess} hidden></button>
        <div className="modal fade" id="releasesModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="releasesModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/admin/users">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Releases Updated Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="artistsModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="artistsModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/admin/users">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Artists Updated Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="modal fade" id="labelsModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="labelsModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/admin/users">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Labels Updated Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>
        
        <div className="modal fade" id="usersErrorModal" tabindex="-1" aria-labelledby="usersErrorModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/admin/users">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-cross-circle text-danger success-icon"></i>
                        <h5>User {existingUser} already exists</h5>
                    </div>
                </div>
            </div>
        </div>

        <div className="modal fade" id="usersModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="usersModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/admin/users">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>Users Uploaded Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdminUsers