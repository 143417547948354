import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import logo from '../../assets/logo.svg'

const Sidebar = () => {
  return (
    <div className='sidebar'>
        <Link to={'/'} className='sidebar-logo'>
            <img src={logo} alt="" />
        </Link>
        <div className="sidebar-links">
            <NavLink to={'panel'} ativeClassName="active">
                <i className="fi fi-rr-house-blank"></i>
                Dashboard
            </NavLink>
            <NavLink to={'releases'} ativeClassName="active">
                <i className="fi fi-ts-rocket-lunch"></i>
                Releases
            </NavLink>
            <NavLink to={'artists'} ativeClassName="active">
                <i className="fi fi-tr-music-note"></i>
                Artists
            </NavLink>
            <NavLink to={'labels'} ativeClassName="active">
                <i className="fi fi-tr-ticket-alt"></i>
                Labels
            </NavLink>
            <NavLink to={'analytics'} ativeClassName="active">
                <i className="fi fi-tr-stats"></i>
                Analytics
            </NavLink>
            <NavLink to={'payouts'} ativeClassName="active">
                <i className="fi fi-tr-usd-circle"></i>
                Payouts
            </NavLink>
            <NavLink to={'claims'} ativeClassName="active">
                <i className="fi fi-tr-memo-circle-check"></i>
                Claims
            </NavLink>
            <NavLink to={'profile'} ativeClassName="active">
                <i className="fi fi-tr-user-pen"></i>
                Profile
            </NavLink>
            <NavLink to={'splits'} ativeClassName="active">
                <i className="fi fi-tr-split"></i>
                Splits
            </NavLink>
        </div>
    </div>
  )
}

export default Sidebar