import React, { useEffect, useState } from 'react'
import logo from "../assets/logo.svg";
import { Link, useNavigate } from 'react-router-dom';
import '../sass/auth.scss'
import p1 from '../assets/microphone.svg'
import p2 from '../assets/music.svg'
import p3 from '../assets/collaborator.svg'
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { child, get, ref, set } from 'firebase/database';
import emails from '../arrays/emails.json'
import axios from 'axios';

const Signup = () => {

    const navigate = useNavigate()

    const [plan, setPlan] = useState(1)

    const [email, setEmail] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [updatesCheck, setUpdatesCheck] = useState(false)
    const [termsCheck, setTermsCheck] = useState(false)
    const [accountType, setAccountType] = useState(['Artist', 'Label', 'Collaborator'])
    const [btnText, setBtnText] = useState('Signup')

    const [error, setError] = useState('')

    const handleError = (errorMsg) => {
        setError(errorMsg)
        setTimeout(() => {
            setError('')
        }, 3000);
    }

    const [usersSplits, setUsersSplits] = useState([])

    useEffect(() => {
        get(ref(db, 'users'))
        .then(snapshot => {
            const usersData = Object.entries(snapshot.val()).map(([userId, user]) => ({
                id: userId,
                ...user
            }))
            const allSplits = usersData.flatMap(user => user.splits).filter(split => split !== undefined)
            setUsersSplits(allSplits)
        })
    }, [])


    const handleSignup = (e) => {
        e.preventDefault()
        if (password !== confirmPassword) {
            handleError('Passwords do not match. Please re-confirm your password.')
        } else if (termsCheck === false) {
            handleError('Please select the checkbox to confirm your agreement.')
        } else if (password.length < 6) {
            handleError('Password must be at least 6 characters.')
        } else {
            setBtnText('Please Wait...')
            createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;

                const inviteSplits = []
                const splitsExist = usersSplits.filter(split => split.user === email)
                splitsExist.forEach(split => {
                    inviteSplits.push({
                        isRecoupment: false,
                        isrc: split.track,
                        revenueShare: 0,
                        title: split.title || '',
                        percentage: split.share
                    })
                })
                
                set(child(ref(db, 'users'), user.uid), {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    accountType: accountType[plan - 1],
                    updatesCheck: updatesCheck,
                    inviteSplits: inviteSplits
                })
                localStorage.setItem('uid', user.uid)
                const emailTemplate = emails[2]
                const subject = emailTemplate.subject
                const content = emailTemplate.content.replace(/\*\|Username\|\*/g, firstName)
                axios.post('https://bne-backend.vercel.app/email', {
                    email: email,
                    subject,
                    content
                }).then(() => navigate('/dashboard/panel'))
            })
            .catch((error) => {
                const errorCode = error.code;
                errorCode === 'auth/email-already-in-use' && handleError('Email already exists.')
            });
        }
    }


  return (
    <div className='auth-page'>

        <form className="container py-5" onSubmit={handleSignup}>
            <div className="row">
                <div className="col-lg-6 auth-container">
                    <Link to={'/'} className='auth-logo'>
                        <img src={logo} alt="" />
                    </Link>

                    <h1>Create new account</h1>
                    <h6>
                        To get started, please provide us with your email address and name
                    </h6>
                    <div className='auth-form'>
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} className='auth-form-input' placeholder='Email' required />
                        <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} className='auth-form-input' placeholder='First Name' required />
                        <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} className='auth-form-input' placeholder='Last Name' required />
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} className='auth-form-input' placeholder='Password' required />
                        <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} className='auth-form-input' placeholder='Confirm Password' required />
                    </div>

                    {/* <div className='row mt-5 pt-4'>
                <h2 className='text-light w-100 mx-2'>Choose account type</h2>
                <div className='col-md-4 col-6 mt-4'>
                    <div className={`plan-card ${plan === 1 && 'active'}`} onClick={() => setPlan(1)}>
                        <img src={p1} alt=""/>
                        <h1>Artist</h1>
                        <p>Unlimited albums and singles for one artist</p>
                        <h6>From <span>$2.99</span> per month</h6>
                    </div>
                </div>
                <div className='col-md-4 col-6 mt-4'>
                    <div className={`plan-card ${plan === 2 && 'active'}`} onClick={() => setPlan(2)}>
                        <img src={p2} alt=""/>
                        <h1>Label</h1>
                        <p>Unlimited albums and singles for unlimited artists</p>
                        <h6>From <span>$5.99</span> per month</h6>
                    </div>
                </div>
                <div className='col-md-4 col-6 mt-4'>
                    <div className={`plan-card ${plan === 3 && 'active'}`} onClick={() => setPlan(3)}>
                        <img src={p3} alt=""/>
                        <h1>Collaborator</h1>
                        <p>Collect royalties from your splits. Receive 100%.</p>
                        <h6>Get started for free</h6>
                    </div>
                </div>
            </div> */}
                </div>
            </div>
            <div className='row mt-4'>
                <div className='signup-checks'>
                    <div className="form-check">
                        <input 
                            className="form-check-input shadow-none" 
                            type="checkbox" 
                            id="check1" 
                            checked={updatesCheck}
                            onChange={() => setUpdatesCheck(!updatesCheck)}
                        />
                        <label className="form-check-label" for="check1">
                            I want to receive updates and news
                        </label>
                    </div>
                    <div className="form-check">
                        <input 
                            className="form-check-input shadow-none" 
                            type="checkbox" 
                            id="check2" 
                            checked={termsCheck}
                            onChange={() => setTermsCheck(!termsCheck)}
                        />
                        <label className="form-check-label" for="check2">
                            I agree to the terms of use
                        </label>
                    </div>
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-lg-6'>
                    {
                        error !== '' && <h5 className='text-danger text-center mb-3'>{error}</h5>
                    }
                    <button className='auth-form-btn' type='submit'>
                        <span>{btnText}</span>
                    </button>
                </div>
            </div>
        </form>
        
    </div>
  )
}

export default Signup