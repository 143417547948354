export const ContactUsPage = () => (
  <div
    data-tf-widget="YSVVWq2l"
    data-tf-opacity="100"
    data-tf-inline-on-mobile
    data-tf-iframe-props="title=BNE Contact Us"
    data-tf-transitive-search-params
    data-tf-auto-focus
    data-tf-medium="snippet"
    data-tf-full-screen
  ></div>
);
