import React, { useEffect } from 'react'
import Panel from '../components/Dashboard/Panel'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Sidebar from '../components/Dashboard/Sidebar'
import Releases from '../components/Dashboard/Releases'
import Payouts from '../components/Dashboard/Payouts'
import Labels from '../components/Dashboard/Labels'
import Artists from '../components/Dashboard/Artists'
import Claims from '../components/Dashboard/Claims'
import AddRelease from '../components/Dashboard/AddRelease'
import Analytics from '../components/Dashboard/Analytics'
import AddArtist from '../components/Dashboard/AddArtist'
import ReleaseDetails from '../components/Dashboard/ReleaseDetails'
import { auth } from '../firebase'
import { onAuthStateChanged } from 'firebase/auth'
import AddLabels from '../components/Dashboard/AddLabels'
import EditRelease from '../components/Dashboard/EditRelease'
import Profile from '../components/Dashboard/Profile'
import Splits from '../components/Dashboard/Splits'
import AddSplits from '../components/Dashboard/AddSplits'

const Dashboard = () => {

    // const navigate = useNavigate()

    // useEffect(() => {
    //     onAuthStateChanged(auth, (user) => {
    //       if (user) {
    //         //
    //       } else {
    //         // User is signed out
    //         localStorage.removeItem('uid')
    //         navigate('/')
    //       }
    //     });
    // }, [])

  return (
    <div className='dashboard'>
        <div className="container">
            <div className="row">
                <div className="col-lg-3 py-2 dash-sidebar">
                    <Sidebar />
                </div>
                <div className="col-lg-9 py-2">
                    {/* <DashboardHeader /> */}
                    <br />
                    <Routes>
                        <Route element={<Panel />} path="panel" />
                        <Route element={<Releases />} path="releases" />
                        <Route element={<AddRelease />} path="releases/create" />
                        <Route element={<ReleaseDetails />} path="releases/:id" />
                        <Route element={<EditRelease />} path="releases/edit/:id" />
                        <Route element={<Payouts />} path="payouts" />
                        <Route element={<Labels />} path="labels" />
                        <Route element={<AddLabels />} path="labels/create" />
                        <Route element={<Artists />} path="artists" />
                        <Route element={<AddArtist />} path="artists/create" />
                        <Route element={<Claims />} path="claims" />
                        <Route element={<Analytics />} path="analytics" />
                        <Route element={<Profile />} path="profile" />
                        <Route element={<Splits />} path="splits" />
                        <Route element={<AddSplits />} path="splits/:id" />
                    </Routes>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Dashboard