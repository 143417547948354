import { child, get, ref, update } from 'firebase/database'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { db } from '../../firebase'

const Artists = () => {

    const uid = localStorage.getItem('uid')
    const [artists, setArtists] = useState([])

    const [name, setName] = useState('')
    const [website, setWebsite] = useState('')
    const [facebook, setFacebook] = useState('')
    const [twitter, setTwitter] = useState('')
    const [instagram, setInstagram] = useState('')
    const [youtube, setYoutube] = useState('')
    const [spotify, setSpotify] = useState('')
    const [apple, setApple] = useState('')
    const [deezer, setDeezer] = useState('')
    const [soundCloud, setSoundCloud] = useState('')

    const [artistId, setArtistId] = useState('')

    useEffect(() => {
        get(child(ref(db, 'users'), uid))
        .then(snapshot => {
            if (snapshot.val().artists !== undefined) {
                setArtists(snapshot.val().artists)
            } else {
                //
            }
        })
    }, [])


    const updateForm = (artist, i) => {
        setName(artist.name);
        setWebsite(artist.website);
        setFacebook(artist.facebook);
        setTwitter(artist.twitter);
        setInstagram(artist.instagram);
        setYoutube(artist.youtube);
        setSpotify(artist.spotify);
        setApple(artist.apple);
        setDeezer(artist.deezer);
        setSoundCloud(artist.soundCloud);
        setArtistId(i)
    }

    const editArtist = () => {
        const updatedArtists = [...artists]
        updatedArtists[artistId] = { 
            name,
            website,
            facebook,
            twitter,
            instagram,
            youtube,
            spotify,
            apple,
            deezer,
            soundCloud
        }
        setArtists(updatedArtists)
        update(child(ref(db, 'users'), uid), {
            artists: updatedArtists
        })
    }

    const deleteArtist = (index) => {

        const updatedArtists = artists.filter((artist, i) => i !== index);
        
        setArtists(updatedArtists);
        update(child(ref(db, 'users'), uid), {
            artists: updatedArtists
        })
    }

    /////////// PAGINATION ///////////
    const itemsPerPage = 25;
    const [currentPage, setCurrentPage] = useState(1);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = artists.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(artists.length / itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


  return (
    <div className='dash-section'>
        <h1 className='dash-title'>Artists</h1>
        <div className="row">
            <div className="col-12 mt-4">
                <div className="dash-card">
                    <div className="dash-card-heading">
                        <h2 className="dash-card-title">
                            Artists
                        </h2>
                        <Link to={'create'} className="dash-card-heading-btn">
                            <i className="fi fi-sr-plus"></i>
                            Add Artist
                        </Link>
                    </div>
                    <div className="table-responsive mt-3">
                        <table className="table dash-table">
                            <thead>
                                <tr>
                                    <td>Name</td>
                                    <td>Artist Image</td>
                                    <td>Spotify URL</td>
                                    <td>Action</td>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    currentItems.map((artist, i) => (
                                        <tr key={i}>
                                            <td>
                                                <div className="table-item">
                                                    {artist.name}
                                                </div>
                                            </td>
                                            <td>
                                                <img className='label-logo' src="https://logopond.com/logos/7a55e3234f9e9db641070988915f9b00.png" alt="" />
                                            </td>
                                            <td>
                                                <div className="table-item">
                                                    <a href={artist.spotify} target='blank'>{artist.spotify}</a>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="table-item d-flex">
                                                    <i 
                                                        onClick={() => updateForm(artist, i)} 
                                                        className="fi fi-rr-pencil table-action-btn a-blue"
                                                        data-bs-toggle="modal" 
                                                        data-bs-target="#editArtist"
                                                    ></i>
                                                    <i 
                                                        onClick={() => deleteArtist(i)} 
                                                        className="fi fi-rr-trash table-action-btn a-red ms-2"
                                                    ></i>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="table-pagination">
                        {Array.from({ length: totalPages }, (_, index) => index + 1).map((pageNumber) => (
                        <button key={pageNumber} className={currentPage === pageNumber && 'active'} onClick={() => handlePageChange(pageNumber)}>
                            {pageNumber}
                        </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>



        <div className="modal fade" id="editArtist" tabindex="-1" aria-labelledby="editArtistLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="editArtistLabel">Edit Artist</h1>
                        <button type="button" className="btn-close" data-bs-toggle="modal" data-bs-target="#editTrackModal"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-12">
                                <h4 className='dash-form-heading'>General Information</h4>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Name</label>
                                    <input value={name} onChange={(e) => setName(e.target.value)} type="text" placeholder='Name' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Website</label>
                                    <input value={website} onChange={(e) => setWebsite(e.target.value)} type="text" placeholder='Website' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                <h4 className='dash-form-heading'>Profile Information</h4>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Facebook</label>
                                    <input value={facebook} onChange={(e) => setFacebook(e.target.value)} type="text" placeholder='Facebook' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Twitter</label>
                                    <input value={twitter} onChange={(e) => setTwitter(e.target.value)} type="text" placeholder='Twitter' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Instagram</label>
                                    <input value={instagram} onChange={(e) => setInstagram(e.target.value)} type="text" placeholder='Instagram' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Youtube</label>
                                    <input value={youtube} onChange={(e) => setYoutube(e.target.value)} type="text" placeholder='Youtube' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Spotify</label>
                                    <input value={spotify} onChange={(e) => setSpotify(e.target.value)} type="text" placeholder='Spotify' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Apple</label>
                                    <input value={apple} onChange={(e) => setApple(e.target.value)} type="text" placeholder='Apple' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>Deezer</label>
                                    <input value={deezer} onChange={(e) => setDeezer(e.target.value)} type="text" placeholder='Deezer' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-md-6 mt-3">
                                <div className="dash-form-group">
                                    <label>SoundCloud</label>
                                    <input value={soundCloud} onChange={(e) => setSoundCloud(e.target.value)} type="text" placeholder='SoundCloud' className='dash-form-input' />
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <div className="dash-form-btns">
                                    <button 
                                        className='dash-form-btn' 
                                        data-bs-toggle="modal" 
                                        data-bs-target="#editTrackModal"
                                        onClick={editArtist}
                                    >Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
  )
}

export default Artists