import React, { useEffect, useRef, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import countries from '../../arrays/countries.json'
import languages from '../../arrays/languages.json'
import genres from '../../arrays/genres.json'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { db, storage } from '../../firebase'
import { set, push, ref, get, child, update } from 'firebase/database'
import { getDownloadURL, uploadBytes, ref as sRef } from 'firebase/storage'


const AdminEditUser = () => {


    const { uid } = useParams()


    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [street1, setStreet1] = useState('')
    const [street2, setStreet2] = useState('')
    const [country, setCountry] = useState('')
    const [state, setState] = useState('')
    const [city, setCity] = useState('')
    const [zip, setZip] = useState('')

    const successBtn = useRef()

    //////////// GET USER'S RELEASES ////////////
    useEffect(() => {
        get(child(ref(db, 'users'), uid))
        .then(snapshot => {
            initialValues(snapshot.val())
        })
    }, [])

    const initialValues = (item) => {
        setFirstName(item.firstName)
        setLastName(item.lastName)
        setStreet1(item.street1)
        setStreet2(item.street2)
        setCountry(item.country)
        setState(item.state)
        setCity(item.city)
        setZip(item.zip)
    }

    
    const editUser = () => {
        
        update(child(ref(db, 'users'), uid), {
            firstName,
            lastName,
            street1,
            street2,
            country,
            state,
            city,
            zip
        })
        successBtn.current.click()
    }


  return (
    <div className='dash-section'>
        <h1 className='dash-title'>Edit User</h1>
        <div className="row">
            <div className="col-12 mt-4">
                <div className="dash-card">
                    <div className="row">
                        <div className="col-12">
                            <h4 className='dash-card-title'>
                                <Link to={'/admin/users'} className='title-back-link'>
                                    <i className="fi fi-rr-arrow-small-left"></i>
                                </Link>
                                Edit User
                            </h4>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>First Name</label>
                                <input value={firstName} onChange={(e) => setFirstName(e.target.value)} type="text" placeholder='First Name' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Last Name</label>
                                <input value={lastName} onChange={(e) => setLastName(e.target.value)} type="text" placeholder='Last Name' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Street 1</label>
                                <input value={street1} onChange={(e) => setStreet1(e.target.value)} type="text" placeholder='Street 1' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Street 2</label>
                                <input value={street2} onChange={(e) => setStreet2(e.target.value)} type="text" placeholder='Street 2' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>Country</label>
                                <input value={country} onChange={(e) => setCountry(e.target.value)} type="text" placeholder='Country' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>State</label>
                                <input value={state} onChange={(e) => setState(e.target.value)} type="text" placeholder='State' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>City</label>
                                <input value={city} onChange={(e) => setCity(e.target.value)} type="text" placeholder='City' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-md-6 mt-3">
                            <div className="dash-form-group">
                                <label>ZIP</label>
                                <input value={zip} onChange={(e) => setZip(e.target.value)} type="text" placeholder='ZIP' className='dash-form-input' />
                            </div>
                        </div>
                        <div className="col-12 mt-3">
                            <div className="dash-form-btns">
                                <button 
                                    className='dash-form-btn' 
                                    onClick={editUser}
                                >Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        
        <button ref={successBtn} data-bs-target="#successModal" data-bs-toggle="modal" hidden></button>
        <div className="modal fade" id="successModal" tabindex="-1" data-bs-backdrop="static" aria-labelledby="successModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-body success-modal">
                        <a className='success-close-btn' href="/admin/users">
                            <i className="fi fi-rr-cross"></i>
                        </a>
                        <i className="fi fi-rr-check-circle text-success success-icon"></i>
                        <h5>User Updated Successfully!</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AdminEditUser