import axios from "axios";
import { useState } from "react";
import { Circle } from "../components/Icon";
import {
  PaginationDoubleBefore,
  PaginationSingleBefore,
  PaginationSingleNext,
  PaginationDoubleNext,
} from "../components/Icon";
import SnackBar from "../components/notification";
import { Link } from "@material-ui/core";
import right from "../assets/right.svg";
import down from "../assets/down.svg";

export const CuratePage = () => {
  const list_arr = [
    "https://open.spotify.com/embed/playlist/4AYquyEFqRhnRLQZPsUJxv?utm_source=generator&theme=0",
    "https://open.spotify.com/embed/playlist/47WC0P6QuyE4F2vu2waaK0?utm_source=generator&theme=0",
    "https://open.spotify.com/embed/playlist/4pM8fFi0ujTY5ZlA2LIucl?utm_source=generator&theme=0",
    "https://open.spotify.com/embed/playlist/52s6bY2bH1CCQpiEgJrxEj?utm_source=generator&theme=0",
    "https://open.spotify.com/embed/playlist/7ixtpp1H9M0rb7DB93Wb0X?utm_source=generator&theme=0",
    "https://open.spotify.com/embed/playlist/0xQ1fJbQ1TsB2LsUslGmBw?utm_source=generator&theme=0",
    "https://open.spotify.com/embed/playlist/1giP7jnCQXI0vAwTSUqE1J?utm_source=generator&theme=0",
    "https://open.spotify.com/embed/playlist/5txwMiXEkXVG5kde6rknSq?utm_source=generator&theme=0",
    "https://open.spotify.com/embed/playlist/08PPh0IMpyHq279ytO7H8h?utm_source=generator&theme=0",
    "https://open.spotify.com/embed/playlist/4OZh0IipGaYpPkL7N0ZiWO?utm_source=generator&theme=0",
    "https://open.spotify.com/embed/playlist/1e1QxsxmjCZTy1YLTLgFd5?utm_source=generator&theme=0",
    "https://open.spotify.com/embed/playlist/41GdDFlK4hbpsPvkPnEql1?utm_source=generator&theme=0",
    "https://open.spotify.com/embed/playlist/6onmAD8DmSv5UU1qqb6SOA?utm_source=generator&theme=0",
    "https://open.spotify.com/embed/playlist/4SGL1ZLXpxRbYr0u6LDxYS?utm_source=generator&theme=0",
  ];

  const [playlist, setPlaylist] = useState("");
  const [notificationOpened, setNotificationOpened] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState("");
  const [message, setMessage] = useState("");

  const closeNotification = () => {
    setNotificationOpened(false);
  };
  const clickSubmit = () => {
    axios
      .post(
        "https://sheet.best/api/sheets/05d3d8dd-5a1d-4554-9a4e-5205db7e3947",
        { playlist }
      )
      .then((res) => {
        if (res.status === 200) {
          setNotificationOpened(true);
          setNotificationStatus("success");
          setMessage("Created Successfully!");
          setPlaylist('')
        }
      })
      .catch((err) => {
        setNotificationOpened(true);
        setNotificationStatus("error");
        setMessage("Error Occurred!");
      });
  };

  return (
    <>
      <div className="home-banner c-banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="banner-text">
                <h1 className="banner-title">Curated by <span>BNE</span> Tastemakers</h1>
                <h3 className="banner-subtitle">
                  Explore the <span>exquisite</span> list of curated playlists below. The carefully crafted playlists target audiences of different music tastes. <span>Send</span> your music for playlisting and get your music <span>heard</span>.
                </h3>
                <a href="#playlists" className="banner-btn">
                    Explore
                    <img src={down} alt="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="playlist-page">

        <SnackBar
          opened={notificationOpened}
          status={notificationStatus}
          message={message}
          onClose={closeNotification}
        />
        {/* <div className="title">Curated by <p2>BNE</p2> Tastemakers</div>
        <div className="list-text">
          <p>
            Explore the exquisite list of curated playlists below. The carefully crafted playlists 
            target audiences of different music tastes. Send your music for playlisting and get
            your music heard.
          </p>
        </div> */}
        <div className="list-container" id="playlists">
          {list_arr.map((item, i) => (
            <iframe
              className="list-item"
              key={i}
              src={item}
              frameBorder="0"
              allowFullScreen=""
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
              title={i}
            ></iframe>
          ))}
        </div>
        <div className="bg"></div>
        <div className="pagination">
          <PaginationDoubleBefore />
          <PaginationSingleBefore />
          <ul>
            <li className="active">1</li>
          </ul>
          <PaginationSingleNext />
          <PaginationDoubleNext />
        </div>
        <div className="add-playlist">
          <div className="add-playlist-left">
            <h1>Add your playlist</h1>
            <div className="input-panel">
              <input
                type="text"
                placeholder="Share your Spotify track URL"
                value={playlist}
                onChange={(e) => setPlaylist(e.target.value)}
              />
              <button onClick={() => clickSubmit()}>SUBMIT</button>
            </div>
          </div>
          <div className="add-playlist-right">
            <Circle />
          </div>
        </div>
      </div>
    </>
  );
};
