import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBM_44-o4i948nAQ9u662MxJLv2ITJVwZY",
    authDomain: "bne-studio-home.firebaseapp.com",
    projectId: "bne-studio-home",
    storageBucket: "bne-studio-home.appspot.com",
    messagingSenderId: "827948673451",
    appId: "1:827948673451:web:447e0df23918a24b9c4aee",
    measurementId: "G-0FPPGDXJTP"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getDatabase(app)
export const auth = getAuth(app)
export const storage = getStorage(app)