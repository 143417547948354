import { useEffect, useState } from "react";
import DistributeLg from "../assets/DistributeLg.png";
import Music1 from "../assets/Music1.png";
import Music2 from "../assets/Music2.png";
import Music3 from "../assets/Music3.png";
import Music4 from "../assets/Music4.png";
import Music5 from "../assets/Music5.png";
import Music6 from "../assets/Music6.png";
import Music7 from "../assets/Music7.png";
import { SubDown, SubUp } from "../components/Icon";
import axios from "axios";
import SnackBar from "../components/notification";

export const DistributePage = () => {
  const [faqList, setFaqList] = useState([
    {
      title: "How do I get my music on streaming platforms?",
      text: "Sign up on BNE Studio. Upload your music once. We'll make it available on all major streaming platforms.",
      showSubText: true,
    },
    {
      title: "I don't have a Spotify artist profile, can I still distribute my songs there?",
      text: "Yes.",
      showSubText: false,
    },
    {
      title: "How long does BNE take to distribute music to streaming platforms?",
      text: "We'll get your music out within 72 hours.",
      showSubText: false,
    },
    {
      title: "How much does it cost to get my music distributed?",
      text: "We have two models. 1) 20% of Revenue or 2) $20 yearly subscription fee (Invite Only)",
      showSubText: false,
    },
    {
      title: "How can I receive my royalties?",
      text: "You get paid every month on your payment method of choice.",
      showSubText: false,
    },
    {
      title: "How do I get my music playlisted?",
      text: "Send us your music for consideration and our editorial team will take a look.",
      showSubText: false,
    },
    {
      title: "Does BNE help with promotion?",
      text: "Yes definitely! Send your music for consideration. We promote on a case-by-case basis",
      showSubText: false,
    },
  ]);

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [artistURL, setArtistURL] = useState("");
  // const [password, setPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");

  const [notificationOpened, setNotificationOpened] = useState(false);
  const [notificationStatus, setNotificationStatus] = useState("");
  const [message, setMessage] = useState("");
  const closeNotification = () => {
    setNotificationOpened(false);
  };

  const onClickSubDrop = (i) => {
    const temp = [...faqList];
    temp[i].showSubText = !temp[i].showSubText;
    setFaqList(temp);
  };

  const createAccount = async () => {
    // if (password !== confirmPassword) {
    //   setNotificationOpened(true);
    //   setNotificationStatus("warning");
    //   setMessage("Confirm password!");
    //   return;
    // }
    axios
      .post(
        "https://sheet.best/api/sheets/2d0493ad-cab7-49dc-bfdd-de629deddc7f",
        { email, name, artistURL }
      )
      .then((res) => {
        if (res.status === 200) {
          setNotificationOpened(true);
          setNotificationStatus("success");
          setMessage("Thank you for showing interest! We'll get back to you shortly!");
          setName('')
          setEmail('')
          setArtistURL('')
        }
      })
      .catch((err) => {
        setNotificationOpened(true);
        setNotificationStatus("error");
        setMessage("Error Occurred!");
      });
  };

  return (
    <div className="distribute-page flex-grow">
    <div className="bg"></div>
      <SnackBar
        opened={notificationOpened}
        status={notificationStatus}
        message={message}
        onClose={closeNotification}
      />
      <div className="distribute-header">
        <div>
          <h1><p2>BNE</p2> Studio can help get your music heard</h1>
          <p>
            Distribute your music at ease to all the major platforms, such as Spotify, Apple Music, YouTube Music, Tidal, Amazon Music and many more, globally. 
            Maximize earnings through your music.
          </p>
        </div>
        <div className="signup-panel">
          <h1>Want to join?</h1>
          <div className="input-panel">
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="text"
              placeholder="Spotify Artist Profile URL"
              value={artistURL}
              onChange={(e) => setArtistURL(e.target.value)}
            />
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button className="create-account" onClick={() => createAccount()}>
            Sign Me Up Please!
          </button>
        </div>
      </div>

      <div className="distribute-faq">
        <h1><p2>BNE</p2> Studio Quick Facts</h1>
        <ul>
          {faqList.map((item, i) =>
            !item.showSubText ? (
              <li key={i}>
                <div>
                  <p>{item.title}</p>
                  <SubUp onClick={() => onClickSubDrop(i)} />
                </div>
              </li>
            ) : (
              <li key={i}>
                <div className="active-faq">
                  <p>{item.title}</p>
                  <SubDown onClick={() => onClickSubDrop(i)} />
                </div>
                <div className="sub-drop">{item.text}</div>
              </li>
            )
          )}
        </ul>
      </div>
    </div>
  );
};
