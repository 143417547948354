import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header/Header';


const Layout = props => {

    const { children } = props;

    return (
        <div className='main-container'>
            <Header />
            {children}
            <Footer />
        </div>
    );
}

export default Layout;